export const couponData = [
    {
      id: 1,
      code: "95954",
      date: "Jun 4, 2020",
      status: "Enable",
      type: "Free Shipping",
      endDate: "Jul 8 2020",
      Paid: "Paid",
      discount: "12",
      check: false,
    },
    {
      id: 2,
      code: "95901",
      date: "Jan 3, 2020",
      status: "Disable",
      type: "Fixed Amount",
      endDate: "Jan 10, 2020",
      Paid: "Paid",
      discount: "23",
      check: false,
    },
    {
      id: 3,
      code: "95363",
      date: "May 29, 2020",
      status: "Enable",
      type: "Percentage",
      endDate: "Jun 26, 2020",
      Paid: "Paid",
      discount: "15",
      check: false,
    },
    {
      id: 4,
      code: "95913",
      date: "May 29, 2020",
      status: "Disable",
      type: "Free Shipping",
      endDate: "Jun 10,2020",
      Paid: "due",
      discount: "9",
      check: false,
    },
    {
      id: 5,
      code: "93947",
      date: "May 28, 2020",
      status: "Enable",
      type: "Fixed Amount",
      endDate: "Jun 1, 2020",
      Paid: "Paid",
      discount: "496.75",
      check: false,
    },
    {
      id: 6,
      code: "95359",
      date: "May 26, 2020",
      status: "Disable",
      type: " Free Shipping",
      endDate: "Jun 12, 2020",
      Paid: "Paid",
      discount: "8",
      check: false,
    },
    {
      id: 7,
      code: "95902",
      date: "May 25, 2020",
      status: "Enable",
      type: "Fixed Amount",
      endDate: "Jun 10, 2020",
      Paid: "cancelled",
      discount: "349.75",
      check: false,
    },
    {
      id: 8,
      code: "91996",
      date: "May 24, 2020",
      status: "Disable",
      type: "Free Shipping",
      endDate: "Jul 1, 2020",
      Paid: "Paid",
      discount: "20",
      check: false,
    },
    {
      id: 9,
      code: "95582",
      date: "May 23, 2020",
      status: "Enable",
      type: " Percentage ",
      endDate: "May 29, 2020",
      Paid: "due",
      discount: "24",
      check: false,
    },
    {
      id: 10,
      code: "95959",
      date: "May 3, 2020",
      status: "Disable",
      type: "Fixed Amount",
      endDate: "May 10, 2020",
      Paid: "due",
      discount: "19",
      check: false,
    },
    {
      id: 11,
      code: "95810",
      date: "Jul 4, 2020",
      status: "Enable",
      type: "Free Shipping",
      endDate: "3 items",
      Paid: "Paid",
      discount: "24",
      check: false,
    },
    {
      id: 12,
      code: "95820",
      date: "Jul 3, 2020",
      status: "Enable",
      type: "Percentage",
      endDate: "Jul 10, 2020",
      Paid: "Paid",
      discount: "9",
      check: false,
    },
    {
      id: 13,
      code: "95830",
      date: "May 29, 2020",
      status: "Enable",
      type: "Free Shipping",
      endDate: "June 16, 2020",
      Paid: "cancelled",
      discount: "14",
      check: false,
    },
    {
      id: 14,
      code: "95150",
      date: "May 29, 2020",
      status: " Enable",
      type: "Fixed Amount",
      endDate: "Jun 18, 2020",
      Paid: "Paid",
      discount: "19",
      check: false,
    },
    {
      id: 15,
      code: "91860",
      date: "May 28, 2020",
      status: "Disable",
      type: "Free Shipping",
      endDate: "Jun 10,2020",
      Paid: "cancelled",
      discount: "4",
      check: false,
    },
    {
      id: 16,
      code: "95970",
      date: "May 26, 2020",
      status: "Enable",
      type: "Fixed Amount",
      endDate: "Jun 12, 2020",
      Paid: "Due",
      discount: "8",
      check: false,
    },
    {
      id: 17,
      code: "92880",
      date: "May 25, 2020",
      status: "Disable",
      type: "Fixed Amount",
      endDate: "Jun 4,2020",
      Paid: "Due",
      discount: "34",
      check: false,
    },
    {
      id: 18,
      code: "96880",
      date: "May 24, 2020",
      status: "On Hold",
      type: "Free Shipping",
      endDate: "Jun 10 2020",
      Paid: "Paid",
      discount: "12",
      check: false,
    },
    {
      id: 19,
      code: "97890",
      date: "May 23, 2020",
      status: "Delivered",
      type: "Fixed Amount",
      endDate: " Jul 10, 2020 ",
      Paid: "Paid",
      discount: "24",
      check: false,
    },
    {
      id: 20,
      code: "98899",
      date: "May 23, 2020",
      status: "Enable",
      type: "Free Shipping",
      endDate: " Jun 12, 2020 ",
      Paid: "Due",
      discount: "19",
      check: false,
    },
  ];
  
  export const saleOrders = [
    {
      id: 1,
      ref: 4947,
      bill: "Enterprize Year Subscription",
      issue: "10-05-2019",
      due: "10-13-2019",
      discount: "599.00",
      status: "Due",
    },
    {
      id: 2,
      ref: 4904,
      bill: "Maintenance Year Subscription",
      issue: "06-19-2019",
      due: "06-26-2019",
      discount: "99.00",
      status: "Paid",
    },
    {
      id: 3,
      ref: 4829,
      bill: "Enterprize Year Subscription",
      issue: "10-04-2018",
      due: "10-12-2018",
      discount: "599.00",
      status: "Paid",
    },
    {
      id: 4,
      ref: 4830,
      bill: "Enterprize Anniversary Subscription",
      issue: "12-04-2018",
      due: "14-12-2018",
      discount: "399.00",
      status: "Paid",
    },
    {
      id: 5,
      ref: 4840,
      bill: "Enterprize Coverage Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      discount: "99.00",
      status: "Cancelled",
    },
    {
      id: 6,
      ref: 4844,
      bill: "	Manual Subscription Adjustments",
      issue: "12-08-2018",
      due: "13-22-2018",
      discount: "99.00",
      status: "Paid",
    },
    {
      id: 7,
      ref: 4847,
      bill: "Automatic Subscription Adjustments",
      issue: "12-08-2018",
      due: "13-22-2018",
      discount: "99.00",
      status: "Paid",
    },
    {
      id: 8,
      ref: 4848,
      bill: "Tiered Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      discount: "99.00",
      status: "Paid",
    },
    {
      id: 9,
      ref: 4849,
      bill: "Tiered Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      discount: "99.00",
      status: "Paid",
    },
    {
      id: 10,
      ref: 4750,
      bill: "Flexible Subscription Plan",
      issue: "12-08-2018",
      due: "13-22-2018",
      discount: "99.00",
      status: "Paid",
    },
    {
      id: 11,
      ref: 4510,
      bill: "Enterprize Year Subscription",
      issue: "10-05-2019",
      due: "10-13-2019",
      discount: "599.00",
      status: "Due",
    },
    {
      id: 12,
      ref: 4520,
      bill: "Maintenance Year Subscription",
      issue: "06-19-2019",
      due: "06-26-2019",
      discount: "99.00",
      status: "Paid",
    },
    {
      id: 13,
      ref: 4530,
      bill: "Enterprize Year Subscription",
      issue: "10-04-2018",
      due: "10-12-2018",
      discount: "599.00",
      status: "Paid",
    },
    {
      id: 14,
      ref: 4560,
      bill: "Enterprize Anniversary Subscription",
      issue: "12-04-2018",
      due: "14-12-2018",
      discount: "399.00",
      status: "Paid",
    },
    {
      id: 15,
      ref: 4570,
      bill: "Enterprize Coverage Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      discount: "99.00",
      status: "Cancelled",
    },
    {
      id: 16,
      ref: 4580,
      bill: "	Manual Subscription Adjustments",
      issue: "12-08-2018",
      due: "13-22-2018",
      discount: "99.00",
      status: "Paid",
    },
    {
      id: 17,
      ref: 4590,
      bill: "Automatic Subscription Adjustments",
      issue: "12-08-2018",
      due: "13-22-2018",
      discount: "99.00",
      status: "Paid",
    },
    {
      id: 18,
      ref: 4600,
      bill: "Tiered Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      discount: "99.00",
      status: "Paid",
    },
    {
      id: 19,
      ref: 4425,
      bill: "Tiered Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      discount: "99.00",
      status: "Paid",
    },
    {
      id: 20,
      ref: 4490,
      bill: "Flexible Subscription Plan",
      issue: "12-08-2018",
      due: "13-22-2018",
      discount: "99.00",
      status: "Paid",
    },
  ];
  
  
  export const PaidOptions = [
    { value: "Paid", label: "Paid" },
    { value: "Due", label: "Due" },
    { value: "Canceled", label: "Cancelled" },
  ];
  
  
  
  export const statusOptions = [
    { value: "Paid", label: "Paid" },
    { value: "Due", label: "Due" },
    { value: "Canceled", label: "Cancelled" },
  ];
  
  export const filterStatus = [
    { value: "approved", label: "Approved" },
    { value: "pending", label: "Pending" },
    { value: "deleted", label: "Deleted" },
    { value: "rejected", label: "Rejected" },
  ];
  
  export const filterType = [
    { value: "Deposit", label: "Deposit" },
    { value: "Buy", label: "Buy Coin" },
    { value: "Sell", label: "Sell Coin" },
    { value: "Transfer", label: "Transfer" },
    { value: "Withdraw", label: "Withdraw" },
  ];
  
  export const filterCoin = [
    { value: "Bitcoin", label: "Bitcoin" },
    { value: "Etherium", label: "Etherium" },
    { value: "Litecoin", label: "Litecoin" },
  ];
  
  export const filterPaymentmethod = [
    { value: "Paypal", label: "Paypal" },
    { value: "Bank", label: "Bank" },
  ];
  