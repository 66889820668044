export const attributesData = [
    {
      id: 1,
      orderId: "Single",
      name: "Abu Bin Ishtiyak",
      email: "info@babyamore.in",
      phone: "+811 847-4958",
      date: "Radio",
      invoiceItem1: "500.00",
      invoiceItem2: "500.00",
      invoiceItem3: "1000.00",
      invoiceItem4: "40.00",
      totalAmount: "2300.00",
      status: "Complete",
    },
    {
      id: 2,
      orderId: "Multiple",
      name: "Ashley Lawson",
      email: "ashley@babyamore.in",
      phone: "+124 394-1787",
      date: "Checkbox",
      invoiceItem1: "50.00",
      invoiceItem2: "50.00",
      invoiceItem3: "10.00",
      invoiceItem4: "40.00",
      totalAmount: "210.00",
      status: "Pending",
    },
    {
      id: 3,
      orderId: "Key",
      name: "Joe Larson",
      email: "larson@example.com",
      phone: "+168  603-2320",
      date: " Pattern",
      invoiceItem1: "50.00",
      invoiceItem2: "200.00",
      invoiceItem3: "200.00",
      invoiceItem4: "50.00",
      totalAmount: "560.00",
      status: "Complete",
    },
    {
      id: 4,
      orderId: "Dervied",
      name: "Jane Montgomery",
      email: "jane84@example.com",
      phone: "+439 271-5360",
      date: " Select",
      invoiceItem1: "1500.00",
      invoiceItem2: "500.00",
      invoiceItem3: "1000.00",
      invoiceItem4: "600.00",
      totalAmount: "3660.00",
      status: "Cancelled",
    },
    {
      id: 5,
      orderId: "Multiple",
      name: "Frances Burns",
      email: "frances@example.com",
      phone: "+639 130-3150",
      date: " Color",
      invoiceItem1: "100.00",
      invoiceItem2: "100.00",
      invoiceItem3: "50.00",
      invoiceItem4: "40.00",
      totalAmount: "350.00",
      status: "Complete",
    },
    {
      id: 6,
      orderId: "Data",
      name: "Alan Butler",
      email: "butler@example.com",
      phone: "+963 309-1706",
      date: "Pattern",
      invoiceItem1: "500.00",
      invoiceItem2: "500.00",
      invoiceItem3: "1000.00",
      invoiceItem4: "300.00",
      totalAmount: "2360.00",
      status: "Complete",
    },
    {
      id: 7,
      orderId: "Taken",
      name: "Victoria Lynch",
      email: "victoria@example.com",
      phone: "+811 985-4846",
      date: "State",
      invoiceItem1: "10.00",
      invoiceItem2: "30.00",
      invoiceItem3: "10.00",
      invoiceItem4: "6.00",
      totalAmount: "126.00",
      status: "Pending",
    },
    {
      id: 8,
      orderId: "Key",
      name: "Patrick Newman",
      email: "patrick@example.com",
      phone: "+942 238-4474",
      date: "Pattern",
      invoiceItem1: "150.00",
      invoiceItem2: "150.00",
      invoiceItem3: "200.00",
      invoiceItem4: "50.00",
      totalAmount: "610.00",
      status: "Complete",
    },
    {
      id: 9,
      orderId: " State",
      name: "Billiard Homes",
      email: "info@babyamore.in",
      phone: "+811 847-4958",
      date: "Const ",
      invoiceItem1: "500.00",
      invoiceItem2: "500.00",
      invoiceItem3: "1000.00",
      invoiceItem4: "40.00",
      totalAmount: "2300.00",
      status: "Complete",
    },
    {
      id: 10,
      orderId: "Key",
      name: "Ashley Anderson",
      email: "ashley@babyamore.in",
      phone: "+124 394-1787",
      date: "Pattern",
      invoiceItem1: "50.00",
      invoiceItem2: "50.00",
      invoiceItem3: "10.00",
      invoiceItem4: "40.00",
      totalAmount: "210.00",
      status: "Pending",
    },
    {
      id: 11,
      orderId: "Place",
      name: "Jim Larson",
      email: "larson@example.com",
      phone: "+168  603-2320",
      date: "Color",
      invoiceItem1: "50.00",
      invoiceItem2: "200.00",
      invoiceItem3: "200.00",
      invoiceItem4: "50.00",
      totalAmount: "560.00",
      status: "Complete",
    },
    {
      id: 12,
      orderId: "System",
      name: "Jane Gomez",
      email: "jane84@example.com",
      phone: "+439 271-5360",
      date: "Lang",
      invoiceItem1: "100.00",
      invoiceItem2: "100.00",
      invoiceItem3: "50.00",
      invoiceItem4: "40.00",
      totalAmount: "350.00",
      status: "Cancelled",
    },
    // {
    //   id: 13,
    //   orderId: "326T4M9",
    //   name: "Frances Airens",
    //   email: "frances@example.com",
    //   phone: "+639 130-3150",
    //   date: "21 Jan 2019, 6 :12 am",
    //   invoiceItem1: "100.00",
    //   invoiceItem2: "100.00",
    //   invoiceItem3: "50.00",
    //   invoiceItem4: "40.00",
    //   totalAmount: "350.00",
    //   status: "Complete",
    // },
    // {
    //   id: 14,
    //   orderId: "746F5K2",
    //   name: "James Butler",
    //   email: "butler@example.com",
    //   phone: "+963 309-1706",
    //   date: "23 Jan 2019, 10:45pm",
    //   invoiceItem1: "500.00",
    //   invoiceItem2: "500.00",
    //   invoiceItem3: "1000.00",
    //   invoiceItem4: "40.00",
    //   totalAmount: "2300.00",
    //   status: "Complete",
    // },
    // {
    //   id: 15,
    //   orderId: "326T4M9",
    //   name: "Jack Simmons",
    //   email: "frances@example.com",
    //   phone: "+639 130-3150",
    //   date: "21 Jan 2019, 6 :12 am",
    //   invoiceItem1: "100.00",
    //   invoiceItem2: "100.00",
    //   invoiceItem3: "50.00",
    //   invoiceItem4: "40.00",
    //   totalAmount: "350.00",
    //   status: "Cancelled",
    // },
  ];
  