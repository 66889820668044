export const productsData = [
    {
      id: 1,
      orderId: "95954",
      date: "TRS1234",
      status: "On Hold",
      customer: "Camera",
      purchased: "3 items",
      Paid: "paid",
      list:"22",
      add :"Admin",
      total: "Lesch, Hauck and Gutkowski",
      check: false,
    },
    {
      id: 2,
      orderId: "95961",
      date: "TES235",
      status: "Delivered",
      customer: "Phones",
      purchased: "Fitness Tracker",
      Paid: "paid",
      list:"22",
      add:"Admin",
      total: "Gutkowski",
      check: false,
    },
    {
      id: 3,
      orderId: "95963",
      date: "TSW2254",
      status: "Delivered",
      customer: "Electronics",
      purchased: "Pink Fitness Tracker",
      Paid: "paid",
      list:"11",
      add:"Sales",
      total: "Gutkowski",
      check: false,
    },
    {
      id: 4,
      orderId: "95933",
      date: "UYT33211",
      status: "Delivered",
      customer: "Graphics",
      purchased: "2 items",
      Paid: "due",
      list:"12",
      add:"14",
      total: "Lesch & Hauck",
      check: false,
    },
    {
      id: 5,
      orderId: "95947",
      date: "YJL8932",
      status: "On Hold",
      customer: "Standards",
      purchased: "6 items",
      Paid: "paid",
      list:"14",
      add:"20",
      total: " Hauck",
      check: false,
    },
    // {
    //   id: 6,
    //   orderId: "95909",
    //   date: "ETX1243",
    //   status: "Delivered",
    //   customer: "Victory",
    //   purchased: "Waterproof Speakers",
    //   Paid: "paid",
    //   total: "89.99",
    //   check: false,
    // },
    // {
    //   id: 7,
    //   orderId: "95902",
    //   date: "SSW1452",
    //   status: "On Hold",
    //   customer: "AMD",
    //   purchased: "4 items",
    //   Paid: "cancelled",
    //   total: "349.75",
    //   check: false,
    // },
    // {
    //   id: 8,
    //   orderId: "95996",
    //   date: "KLE1653",
    //   status: "On Hold",
    //   customer: "Electrons",
    //   purchased: "Smart Watch",
    //   Paid: "paid",
    //   total: "129.99",
    //   check: false,
    // },
    // {
    //   id: 9,
    //   orderId: "95982",
    //   date: "LKS8733",
    //   status: "Delivered",
    //   customer: "Markups",
    //   purchased: "Smart Watch",
    //   Paid: "due",
    //   total: "249.75",
    //   check: false,
    // },
    // {
    //   id: 10,
    //   orderId: "95959",
    //   date: "TEW3908",
    //   status: "Delivered",
    //   customer: "Lates",
    //   purchased: "Waterproof Speaker",
    //   Paid: "due",
    //   total: "19.99",
    //   check: false,
    // },
    // {
    //   id: 11,
    //   orderId: "95810",
    //   date: "JOP2229",
    //   status: "On Hold",
    //   customer: "LLC",
    //   purchased: "3 items",
    //   Paid: "paid",
    //   total: "249.75",
    //   check: false,
    // },
    // {
    //   id: 12,
    //   orderId: "95820",
    //   date: "KEW2334",
    //   status: "Delivered",
    //   customer: "Kites",
    //   purchased: "Fitness Tracker",
    //   Paid: "paid",
    //   total: "99.49",
    //   check: false,
    // },
    // {
    //   id: 13,
    //   orderId: "95830",
    //   date: "MQA1289",
    //   status: "Delivered",
    //   customer: "Rights",
    //   purchased: "Pink Fitness Tracker",
    //   Paid: "cancelled",
    //   total: "148.75",
    //   check: false,
    // },
    // {
    //   id: 14,
    //   orderId: "95850",
    //   date: "MPU9875",
    //   status: "Delivered",
    //   customer: "Sony",
    //   purchased: "2 items",
    //   Paid: "paid",
    //   total: "199.75",
    //   check: false,
    // },
    // {
    //   id: 15,
    //   orderId: "95860",
    //   date: "TES2345",
    //   status: "On Hold",
    //   customer: "Transcent",
    //   purchased: "6 items",
    //   Paid: "cancelled",
    //   total: "496.75",
    //   check: false,
    // },
    // {
    //   id: 16,
    //   orderId: "95870",
    //   date: "TRE2330",
    //   status: "Delivered",
    //   customer: "Hive",
    //   purchased: "Waterproof Speakers",
    //   Paid: "Due",
    //   total: "89.99",
    //   check: false,
    // },
    // {
    //   id: 17,
    //   orderId: "95880",
    //   date: "ME3452",
    //   status: "On Hold",
    //   customer: "Titains",
    //   purchased: "4 items",
    //   Paid: "Due",
    //   total: "349.75",
    //   check: false,
    // },
    // {
    //   id: 18,
    //   orderId: "95880",
    //   date: "MRE2290",
    //   status: "On Hold",
    //   customer: "Sony",
    //   purchased: "Smart Watch",
    //   Paid: "paid",
    //   total: "129.99",
    //   check: false,
    // },
    // {
    //   id: 19,
    //   orderId: "95890",
    //   date: "MAW2390",
    //   status: "Delivered",
    //   customer: "KATE",
    //   purchased: "Smart Watch",
    //   Paid: "paid",
    //   total: "249.75",
    //   check: false,
    // },
    // {
    //   id: 20,
    //   orderId: "95899",
    //   date: "MIY2342",
    //   status: "Delivered",
    //   customer: "Kite",
    //   purchased: "Waterproof Speaker",
    //   Paid: "Due",
    //   total: "19.99",
    //   check: false,
    // },
  ];
  
  export const attriData = [
    {
      id: 1,
      orderId: "95954",
      date: "TRS1234",
      status: "On Hold",
      customer: "Camera",
      purchased: "3 items",
      Paid: "paid",
      total: "249.75",
      check: false,
    },
    {
      id: 2,
      orderId: "95961",
      date: "TES235",
      status: "Delivered",
      customer: "Phones",
      purchased: "Fitness Tracker",
      Paid: "paid",
      total: "99.49",
      check: false,
    },
    {
      id: 3,
      orderId: "95963",
      date: "TSW2254",
      status: "Delivered",
      customer: "Electronics",
      purchased: "Pink Fitness Tracker",
      Paid: "paid",
      total: "148.75",
      check: false,
    },
    {
      id: 4,
      orderId: "95933",
      date: "UYT33211",
      status: "Delivered",
      customer: "Graphics",
      purchased: "2 items",
      Paid: "due",
      total: "199.75",
      check: false,
    },
    {
      id: 5,
      orderId: "95947",
      date: "YJL8932",
      status: "On Hold",
      customer: "Standards",
      purchased: "6 items",
      Paid: "paid",
      total: "496.75",
      check: false,
    },
    {
      id: 6,
      orderId: "95909",
      date: "ETX1243",
      status: "Delivered",
      customer: "Victory",
      purchased: "Waterproof Speakers",
      Paid: "paid",
      total: "89.99",
      check: false,
    },
    {
      id: 7,
      orderId: "95902",
      date: "SSW1452",
      status: "On Hold",
      customer: "AMD",
      purchased: "4 items",
      Paid: "cancelled",
      total: "349.75",
      check: false,
    },
    {
      id: 8,
      orderId: "95996",
      date: "KLE1653",
      status: "On Hold",
      customer: "Electrons",
      purchased: "Smart Watch",
      Paid: "paid",
      total: "129.99",
      check: false,
    },
    {
      id: 9,
      orderId: "95982",
      date: "LKS8733",
      status: "Delivered",
      customer: "Markups",
      purchased: "Smart Watch",
      Paid: "due",
      total: "249.75",
      check: false,
    },
    {
      id: 10,
      orderId: "95959",
      date: "TEW3908",
      status: "Delivered",
      customer: "Lates",
      purchased: "Waterproof Speaker",
      Paid: "due",
      total: "19.99",
      check: false,
    },
    {
      id: 11,
      orderId: "95810",
      date: "JOP2229",
      status: "On Hold",
      customer: "LLC",
      purchased: "3 items",
      Paid: "paid",
      total: "249.75",
      check: false,
    },
    {
      id: 12,
      orderId: "95820",
      date: "KEW2334",
      status: "Delivered",
      customer: "Kites",
      purchased: "Fitness Tracker",
      Paid: "paid",
      total: "99.49",
      check: false,
    },
    {
      id: 13,
      orderId: "95830",
      date: "MQA1289",
      status: "Delivered",
      customer: "Rights",
      purchased: "Pink Fitness Tracker",
      Paid: "cancelled",
      total: "148.75",
      check: false,
    },
    {
      id: 14,
      orderId: "95850",
      date: "MPU9875",
      status: "Delivered",
      customer: "Sony",
      purchased: "2 items",
      Paid: "paid",
      total: "199.75",
      check: false,
    },
    {
      id: 15,
      orderId: "95860",
      date: "TES2345",
      status: "On Hold",
      customer: "Transcent",
      purchased: "6 items",
      Paid: "cancelled",
      total: "496.75",
      check: false,
    },
    {
      id: 16,
      orderId: "95870",
      date: "TRE2330",
      status: "Delivered",
      customer: "Hive",
      purchased: "Waterproof Speakers",
      Paid: "Due",
      total: "89.99",
      check: false,
    },
    {
      id: 17,
      orderId: "95880",
      date: "ME3452",
      status: "On Hold",
      customer: "Titains",
      purchased: "4 items",
      Paid: "Due",
      total: "349.75",
      check: false,
    },
    {
      id: 18,
      orderId: "95880",
      date: "MRE2290",
      status: "On Hold",
      customer: "Sony",
      purchased: "Smart Watch",
      Paid: "paid",
      total: "129.99",
      check: false,
    },
    {
      id: 19,
      orderId: "95890",
      date: "MAW2390",
      status: "Delivered",
      customer: "KATE",
      purchased: "Smart Watch",
      Paid: "paid",
      total: "249.75",
      check: false,
    },
    {
      id: 20,
      orderId: "95899",
      date: "MIY2342",
      status: "Delivered",
      customer: "Kite",
      purchased: "Waterproof Speaker",
      Paid: "Due",
      total: "19.99",
      check: false,
    },
  ];
  
  export const saleOrders = [
    {
      id: 1,
      ref: 4947,
      bill: "Enterprize Year Subscription",
      issue: "10-05-2019",
      due: "10-13-2019",
      total: "599.00",
      status: "Due",
    },
    {
      id: 2,
      ref: 4904,
      bill: "Maintenance Year Subscription",
      issue: "06-19-2019",
      due: "06-26-2019",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 3,
      ref: 4829,
      bill: "Enterprize Year Subscription",
      issue: "10-04-2018",
      due: "10-12-2018",
      total: "599.00",
      status: "Paid",
    },
    {
      id: 4,
      ref: 4830,
      bill: "Enterprize Anniversary Subscription",
      issue: "12-04-2018",
      due: "14-12-2018",
      total: "399.00",
      status: "Paid",
    },
    {
      id: 5,
      ref: 4840,
      bill: "Enterprize Coverage Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Cancelled",
    },
    {
      id: 6,
      ref: 4844,
      bill: "	Manual Subscription Adjustments",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 7,
      ref: 4847,
      bill: "Automatic Subscription Adjustments",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 8,
      ref: 4848,
      bill: "Tiered Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 9,
      ref: 4849,
      bill: "Tiered Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 10,
      ref: 4750,
      bill: "Flexible Subscription Plan",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 11,
      ref: 4510,
      bill: "Enterprize Year Subscription",
      issue: "10-05-2019",
      due: "10-13-2019",
      total: "599.00",
      status: "Due",
    },
    {
      id: 12,
      ref: 4520,
      bill: "Maintenance Year Subscription",
      issue: "06-19-2019",
      due: "06-26-2019",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 13,
      ref: 4530,
      bill: "Enterprize Year Subscription",
      issue: "10-04-2018",
      due: "10-12-2018",
      total: "599.00",
      status: "Paid",
    },
    {
      id: 14,
      ref: 4560,
      bill: "Enterprize Anniversary Subscription",
      issue: "12-04-2018",
      due: "14-12-2018",
      total: "399.00",
      status: "Paid",
    },
    {
      id: 15,
      ref: 4570,
      bill: "Enterprize Coverage Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Cancelled",
    },
    {
      id: 16,
      ref: 4580,
      bill: "	Manual Subscription Adjustments",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 17,
      ref: 4590,
      bill: "Automatic Subscription Adjustments",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 18,
      ref: 4600,
      bill: "Tiered Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 19,
      ref: 4425,
      bill: "Tiered Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 20,
      ref: 4490,
      bill: "Flexible Subscription Plan",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
  ];
  
  
  export const paidOptions = [
    { value: "Paid", label: "Paid" },
    { value: "Due", label: "Due" },
    { value: "Canceled", label: "Cancelled" },
  ];
  
  
  
  export const statusOptions = [
    { value: "Paid", label: "Paid" },
    { value: "Due", label: "Due" },
    { value: "Canceled", label: "Cancelled" },
  ];
  
  export const filterStatus = [
    { value: "approved", label: "Approved" },
    { value: "pending", label: "Pending" },
    { value: "deleted", label: "Deleted" },
    { value: "rejected", label: "Rejected" },
  ];
  
  export const filterType = [
    { value: "Deposit", label: "Deposit" },
    { value: "Buy", label: "Buy Coin" },
    { value: "Sell", label: "Sell Coin" },
    { value: "Transfer", label: "Transfer" },
    { value: "Withdraw", label: "Withdraw" },
  ];
  
  export const filterCoin = [
    { value: "Bitcoin", label: "Bitcoin" },
    { value: "Etherium", label: "Etherium" },
    { value: "Litecoin", label: "Litecoin" },
  ];
  
  export const filterPaymentmethod = [
    { value: "Paypal", label: "Paypal" },
    { value: "Bank", label: "Bank" },
  ];
  