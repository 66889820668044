export const orderData = [
  {
    id: 1,
    orderId: "95954",
    date: "Jun 4, 2020",
    status: "On Hold",
    customer: "Arnold Armstrong",
    shop: "Arya Shops",
    purchased: "Payment Done",
    Paid: "paid",
    total: "249.75",
    check: false,
  },
  {
    id: 2,
    orderId: "95961",
    date: "Jun 3, 2020",
    status: "Delivered",
    shop: "Sam PVT",
    customer: "Jean Douglas",
    purchased: "Payment Done",
    Paid: "paid",
    total: "99.49",
    check: false,
  },
  {
    id: 3,
    orderId: "95963",
    date: "May 29, 2020",
    status: "Delivered",
    shop: "RAM Service",
    customer: "Ashley Lawson",
    purchased: "Payment Done",
    Paid: "paid",
    total: "148.75",
    check: false,
  },
  {
    id: 4,
    orderId: "95933",
    date: "May 29, 2020",
    status: "Delivered",
    shop: "KH Sales",
    customer: "Joe Larson",
    purchased: "Waiting for Payment",
    Paid: "due",
    total: "199.75",
    check: false,
  },
  {
    id: 5,
    orderId: "95947",
    date: "May 28, 2020",
    status: "On Hold",
    shop:" Devi Shop",
    customer: "Frances Burns",
    purchased: "Payment  Done",
    Paid: "paid",
    total: "496.75",
    check: false,
  },
  {
    id: 6,
    orderId: "95909",
    date: "May 26, 2020",
    status: "Delivered",
    shop: "Sam Sales",
    customer: "Victoria Lynch",
    purchased: "Payment Done",
    Paid: "paid",
    total: "89.99",
    check: false,
  },
  {
    id: 7,
    orderId: "95902",
    date: "May 25, 2020",
    status: "On Hold",
    customer: "Patrick Newman",
    purchased: "Order Cancelled",
    shop: "Standard",
    Paid: "cancelled",
    total: "349.75",
    check: false,
  },
  {
    id: 8,
    orderId: "95996",
    date: "May 24, 2020",
    status: "On Hold",
    shop:"Standard",
    customer: "Emma Walker",
    purchased: "Payment Done",
    Paid: "paid",
    total: "129.99",
    check: false,
  },
  {
    id: 9,
    orderId: "95982",
    date: "May 23, 2020",
    status: "Delivered",
    shop:"Devi Sales",
    customer: "Jane Montegomery",
    purchased: "Waiting for Payment",
    Paid: "due",
    total: "249.75",
    check: false,
  },
  {
    id: 10,
    orderId: "95959",
    date: "May 23, 2020",
    status: "Delivered",
    shop:"Sri Shops",
    customer: "Jane Harris",
    purchased: "Waiting for Payment",
    Paid: "due",
    total: "19.99",
    check: false,
  },
  {
    id: 11,
    orderId: "95810",
    date: "Jul 4, 2020",
    status: "On Hold",
    shop:"Devi Shops",
    customer: "Jakoby Roman",
    purchased: "Payment Done",
    Paid: "paid",
    total: "249.75",
    check: false,
  },
  {
    id: 12,
    orderId: "95820",
    date: "Jul 3, 2020",
    status: "Delivered",
    shop:"Standard",
    customer: "Katherine Moss",
    purchased: "Payment Done",
    Paid: "paid",
    total: "99.49",
    check: false,
  },
  {
    id: 13,
    orderId: "95830",
    date: "May 29, 2020",
    status: "Delivered",
    shop:"Devi",
    customer: "Rachel Leonard",
    purchased: "Order Cancelled",
    Paid: "cancelled",
    total: "148.75",
    check: false,
  },
  {
    id: 14,
    orderId: "95850",
    date: "May 29, 2020",
    status: "Delivered",
    shop:"Devi",
    customer: "Ada Laine",
    purchased: "Payment Done",
    Paid: "paid",
    total: "199.75",
    check: false,
  },
  {
    id: 15,
    orderId: "95860",
    date: "May 28, 2020",
    status: "On Hold",
    shop:"Sam Shop",
    customer: "Arden Dean",
    purchased: "Order Cancelled",
    Paid: "cancelled",
    total: "496.75",
    check: false,
  },
  {
    id: 16,
    orderId: "95870",
    date: "May 26, 2020",
    status: "Delivered",
    shop:"Deepa Sales",
    customer: "Hector Lovett",
    purchased: "Waiting for Payments",
    Paid: "Due",
    total: "89.99",
    check: false,
  },
  {
    id: 17,
    orderId: "95880",
    date: "May 25, 2020",
    status: "On Hold",
    shop:"Design Sales",
    customer: "Tianna Fuller",
    purchased: "Waiting For Payment",
    Paid: "Due",
    total: "349.75",
    check: false,
  },
  {
    id: 18,
    orderId: "95880",
    date: "May 24, 2020",
    status: "On Hold",
    shop:"Devi Sales",
    customer: "Aliah Pulis",
    purchased: "Payment Done",
    Paid: "paid",
    total: "129.99",
    check: false,
  },
  {
    id: 19,
    orderId: "95890",
    date: "May 23, 2020",
    status: "Delivered",
    shop:"Devi",
    customer: "Kendal Gray",
    purchased: "Payment Done",
    Paid: "paid",
    total: "24.75",
    check: false,
  },
  {
    id: 20,
    orderId: "95899",
    date: "May 23, 2020",
    status: "Delivered",
    shop:"Devi",
    customer: "Kelis Ford",
    purchased: "Waiting for Payments",
    Paid: "Due",
    total: "19.99",
    check: false,
  },
];

export const atData = [
  {
    id: 1,
    orderId: "95954",
    date: "Jun 4, 2020",
    status: "On Hold",
    customer: "Arnold Armstrong",
    shop: "Arya Shops",
    purchased: "Payment Done",
    Paid: "paid",
    total: "249.75",
    check: false,
  },
  {
    id: 2,
    orderId: "95961",
    date: "Jun 3, 2020",
    status: "Delivered",
    shop: "Sam PVT",
    customer: "Jean Douglas",
    purchased: "Payment Done",
    Paid: "paid",
    total: "99.49",
    check: false,
  },
  {
    id: 3,
    orderId: "95963",
    date: "May 29, 2020",
    status: "Delivered",
    shop: "RAM Service",
    customer: "Ashley Lawson",
    purchased: "Payment Done",
    Paid: "paid",
    total: "148.75",
    check: false,
  },
  {
    id: 4,
    orderId: "95933",
    date: "May 29, 2020",
    status: "Delivered",
    shop: "KH Sales",
    customer: "Joe Larson",
    purchased: "Waiting for Payment",
    Paid: "due",
    total: "199.75",
    check: false,
  },
  {
    id: 5,
    orderId: "95947",
    date: "May 28, 2020",
    status: "On Hold",
    shop:" Devi Shop",
    customer: "Frances Burns",
    purchased: "Payment  Done",
    Paid: "paid",
    total: "496.75",
    check: false,
  },
  {
    id: 6,
    orderId: "95909",
    date: "May 26, 2020",
    status: "Delivered",
    shop: "Sam Sales",
    customer: "Victoria Lynch",
    purchased: "Payment Done",
    Paid: "paid",
    total: "89.99",
    check: false,
  },
  {
    id: 7,
    orderId: "95902",
    date: "May 25, 2020",
    status: "On Hold",
    customer: "Patrick Newman",
    purchased: "Order Cancelled",
    shop: "Standard",
    Paid: "cancelled",
    total: "349.75",
    check: false,
  },
  {
    id: 8,
    orderId: "95996",
    date: "May 24, 2020",
    status: "On Hold",
    shop:"Standard",
    customer: "Emma Walker",
    purchased: "Payment Done",
    Paid: "paid",
    total: "129.99",
    check: false,
  },
  {
    id: 9,
    orderId: "95982",
    date: "May 23, 2020",
    status: "Delivered",
    shop:"Devi Sales",
    customer: "Jane Montegomery",
    purchased: "Waiting for Payment",
    Paid: "due",
    total: "249.75",
    check: false,
  },
  {
    id: 10,
    orderId: "95959",
    date: "May 23, 2020",
    status: "Delivered",
    shop:"Sri Shops",
    customer: "Jane Harris",
    purchased: "Waiting for Payment",
    Paid: "due",
    total: "19.99",
    check: false,
  },
  {
    id: 11,
    orderId: "95810",
    date: "Jul 4, 2020",
    status: "On Hold",
    shop:"Devi Shops",
    customer: "Jakoby Roman",
    purchased: "Payment Done",
    Paid: "paid",
    total: "249.75",
    check: false,
  },
  {
    id: 12,
    orderId: "95820",
    date: "Jul 3, 2020",
    status: "Delivered",
    shop:"Standard",
    customer: "Katherine Moss",
    purchased: "Payment Done",
    Paid: "paid",
    total: "99.49",
    check: false,
  },
  {
    id: 13,
    orderId: "95830",
    date: "May 29, 2020",
    status: "Delivered",
    shop:"Devi",
    customer: "Rachel Leonard",
    purchased: "Order Cancelled",
    Paid: "cancelled",
    total: "148.75",
    check: false,
  },
  {
    id: 14,
    orderId: "95850",
    date: "May 29, 2020",
    status: "Delivered",
    shop:"Devi",
    customer: "Ada Laine",
    purchased: "Payment Done",
    Paid: "paid",
    total: "199.75",
    check: false,
  },
  {
    id: 15,
    orderId: "95860",
    date: "May 28, 2020",
    status: "On Hold",
    shop:"Sam Shop",
    customer: "Arden Dean",
    purchased: "Order Cancelled",
    Paid: "cancelled",
    total: "496.75",
    check: false,
  },
  {
    id: 16,
    orderId: "95870",
    date: "May 26, 2020",
    status: "Delivered",
    shop:"Deepa Sales",
    customer: "Hector Lovett",
    purchased: "Waiting for Payments",
    Paid: "Due",
    total: "89.99",
    check: false,
  },
  {
    id: 17,
    orderId: "95880",
    date: "May 25, 2020",
    status: "On Hold",
    shop:"Design Sales",
    customer: "Tianna Fuller",
    purchased: "Waiting For Payment",
    Paid: "Due",
    total: "349.75",
    check: false,
  },
  {
    id: 18,
    orderId: "95880",
    date: "May 24, 2020",
    status: "On Hold",
    shop:"Devi Sales",
    customer: "Aliah Pulis",
    purchased: "Payment Done",
    Paid: "paid",
    total: "129.99",
    check: false,
  },
  {
    id: 19,
    orderId: "95890",
    date: "May 23, 2020",
    status: "Delivered",
    shop:"Devi",
    customer: "Kendal Gray",
    purchased: "Payment Done",
    Paid: "paid",
    total: "24.75",
    check: false,
  },
  {
    id: 20,
    orderId: "95899",
    date: "May 23, 2020",
    status: "Delivered",
    shop:"Devi",
    customer: "Kelis Ford",
    purchased: "Waiting for Payments",
    Paid: "Due",
    total: "19.99",
    check: false,
  },
];





export const orderDates = [
  {
    id: 1,
    orderId: "95954",
    date: "Jun 4, 2020",
    status: "On Hold",
    customer: "Arnold Armstrong",
    shop: "Arya Shops",
    purchased: "Payment Done",
    Paid: "paid",
    total: "249.75",
    check: false,
  },
  {
    id: 2,
    orderId: "95961",
    date: "Jun 3, 2020",
    status: "Delivered",
    shop: "Sam PVT",
    customer: "Jean Douglas",
    purchased: "Payment Done",
    Paid: "paid",
    total: "99.49",
    check: false,
  },
  {
    id: 3,
    orderId: "95963",
    date: "May 29, 2020",
    status: "Delivered",
    shop: "RAM Service",
    customer: "Ashley Lawson",
    purchased: "Payment Done",
    Paid: "paid",
    total: "148.75",
    check: false,
  },
  {
    id: 4,
    orderId: "95933",
    date: "May 29, 2020",
    status: "Delivered",
    shop: "KH Sales",
    customer: "Joe Larson",
    purchased: "Waiting for Payment",
    Paid: "due",
    total: "199.75",
    check: false,
  },
  {
    id: 5,
    orderId: "95947",
    date: "May 28, 2020",
    status: "On Hold",
    shop:" Devi Shop",
    customer: "Frances Burns",
    purchased: "Payment  Done",
    Paid: "paid",
    total: "496.75",
    check: false,
  },
  {
    id: 6,
    orderId: "95909",
    date: "May 26, 2020",
    status: "Delivered",
    shop: "Sam Sales",
    customer: "Victoria Lynch",
    purchased: "Payment Done",
    Paid: "paid",
    total: "89.99",
    check: false,
  },
  {
    id: 7,
    orderId: "95902",
    date: "May 25, 2020",
    status: "On Hold",
    customer: "Patrick Newman",
    purchased: "Order Cancelled",
    shop: "Standard",
    Paid: "cancelled",
    total: "349.75",
    check: false,
  },
  {
    id: 8,
    orderId: "95996",
    date: "May 24, 2020",
    status: "On Hold",
    shop:"Standard",
    customer: "Emma Walker",
    purchased: "Payment Done",
    Paid: "paid",
    total: "129.99",
    check: false,
  },
  {
    id: 9,
    orderId: "95982",
    date: "May 23, 2020",
    status: "Delivered",
    shop:"Devi Sales",
    customer: "Jane Montegomery",
    purchased: "Waiting for Payment",
    Paid: "due",
    total: "249.75",
    check: false,
  },
  {
    id: 10,
    orderId: "95959",
    date: "May 23, 2020",
    status: "Delivered",
    shop:"Sri Shops",
    customer: "Jane Harris",
    purchased: "Waiting for Payment",
    Paid: "due",
    total: "19.99",
    check: false,
  },
  {
    id: 11,
    orderId: "95810",
    date: "Jul 4, 2020",
    status: "On Hold",
    shop:"Devi Shops",
    customer: "Jakoby Roman",
    purchased: "Payment Done",
    Paid: "paid",
    total: "249.75",
    check: false,
  },
  {
    id: 12,
    orderId: "95820",
    date: "Jul 3, 2020",
    status: "Delivered",
    shop:"Standard",
    customer: "Katherine Moss",
    purchased: "Payment Done",
    Paid: "paid",
    total: "99.49",
    check: false,
  },
  {
    id: 13,
    orderId: "95830",
    date: "May 29, 2020",
    status: "Delivered",
    shop:"Devi",
    customer: "Rachel Leonard",
    purchased: "Order Cancelled",
    Paid: "cancelled",
    total: "148.75",
    check: false,
  },
  {
    id: 14,
    orderId: "95850",
    date: "May 29, 2020",
    status: "Delivered",
    shop:"Devi",
    customer: "Ada Laine",
    purchased: "Payment Done",
    Paid: "paid",
    total: "199.75",
    check: false,
  },
  {
    id: 15,
    orderId: "95860",
    date: "May 28, 2020",
    status: "On Hold",
    shop:"Sam Shop",
    customer: "Arden Dean",
    purchased: "Order Cancelled",
    Paid: "cancelled",
    total: "496.75",
    check: false,
  },
  {
    id: 16,
    orderId: "95870",
    date: "May 26, 2020",
    status: "Delivered",
    shop:"Deepa Sales",
    customer: "Hector Lovett",
    purchased: "Waiting for Payments",
    Paid: "Due",
    total: "89.99",
    check: false,
  },
  {
    id: 17,
    orderId: "95880",
    date: "May 25, 2020",
    status: "On Hold",
    shop:"Design Sales",
    customer: "Tianna Fuller",
    purchased: "Waiting For Payment",
    Paid: "Due",
    total: "349.75",
    check: false,
  },
  {
    id: 18,
    orderId: "95880",
    date: "May 24, 2020",
    status: "On Hold",
    shop:"Devi Sales",
    customer: "Aliah Pulis",
    purchased: "Payment Done",
    Paid: "paid",
    total: "129.99",
    check: false,
  },
  {
    id: 19,
    orderId: "95890",
    date: "May 23, 2020",
    status: "Delivered",
    shop:"Devi",
    customer: "Kendal Gray",
    purchased: "Payment Done",
    Paid: "paid",
    total: "24.75",
    check: false,
  },
  {
    id: 20,
    orderId: "95899",
    date: "May 23, 2020",
    status: "Delivered",
    shop:"Devi",
    customer: "Kelis Ford",
    purchased: "Waiting for Payments",
    Paid: "Due",
    total: "19.99",
    check: false,
  },
];

export const orderDatas = [
  {
    id: 1,
    orderId: "9595ee4",
    date: "Jun 4, 2020",
    items:"Apple",
    status: "On Hold",
    customer: "Arnold Armstrong",
    shop: "Arya Shops",
    purchased: "12",
    Paid: "paid",
    total: "249.75",
    check: false,
  },
  {
    id: 2,
    orderId: "95961",
    date: "Jun 3, 2020",
    items:"Orange",
    status: "Delivered",
    shop: "Sam PVT",
    customer: "Jean Douglas",
    purchased: "12 ",
    Paid: "paid",
    total: "99.49",
    check: false,
  },
  {
    id: 3,
    orderId: "95963",
    date: "May 29, 2020",
    status: "Delivered",
    items:"Watermelon",
    shop: "RAM Service",
    customer: "Ashley Lawson",
    purchased: "132",
    Paid: "paid",
    total: "148.75",
    check: false,
  },
  {
    id: 4,
    orderId: "95933",
    date: "May 29, 2020",
    status: "Delivered",
    items:"Sugar",
    shop: "KH Sales",
    customer: "Joe Larson",
    purchased: "12",
    Paid: "due",
    total: "199.75",
    check: false,
  },
  {
    id: 5,
    orderId: "95947",
    items:"Rice",
    date: "May 28, 2020",
    status: "On Hold",
    shop:" Devi Shop",
    customer: "Frances Burns",
    purchased: "12",
    Paid: "paid",
    total: "496.75",
    check: false,
  },
  {
    id: 6,
    orderId: "95909",
    items:"Fans",
    date: "May 26, 2020",
    status: "Delivered",
    shop: "Sam Sales",
    customer: "Victoria Lynch",
    purchased: "10",
    Paid: "paid",
    total: "89.99",
    check: false,
  },
  {
    id: 7,
    orderId: "95902",
    date: "May 25, 2020",
    status: "On Hold",
    items:"Mango",
    customer: "Patrick Newman",
    purchased: "10",
    shop: "Standard",
    Paid: "cancelled",
    total: "349.75",
    check: false,
  },
  {
    id: 8,
    orderId: "95996",
    date: "May 24, 2020",
    status: "On Hold",
    items:"Rings",
    shop:"Standard",
    customer: "Emma Walker",
    purchased: " 12 ",
    Paid: "paid",
    total: "129.99",
    check: false,
  },
  {
    id: 9,
    orderId: "95982",
    date: "May 23, 2020",
    status: "Delivered",
    items:"Keyboard",
    shop:"Devi Sales",
    customer: "Jane Montegomery",
    purchased: "12",
    Paid: "due",
    total: "249.75",
    check: false,
  },
  {
    id: 10,
    orderId: "95959",
    date: "May 23, 2020",
    items:"Memory Cards",
    status: "Delivered",
    shop:"Sri Shops",
    customer: "Jane Harris",
    purchased: "12",
    Paid: "due",
    total: "19.99",
    check: false,
  },
  {
    id: 11,
    orderId: "95810",
    date: "Jul 4, 2020",
    items:"Plugs",
    status: "On Hold",
    shop:"Devi Shops",
    customer: "Jakoby Roman",
    purchased: "12",
    Paid: "paid",
    total: "249.75",
    check: false,
  },
  {
    id: 12,
    orderId: "95820",
    date: "Jul 3, 2020",
    items:"Cups",
    status: "Delivered",
    shop:"Standard",
    customer: "Katherine Moss",
    purchased: "12",
    Paid: "paid",
    total: "99.49",
    check: false,
  },
  {
    id: 13,
    orderId: "95830",
    date: "May 29, 2020",
    status: "Delivered",
    items:"Pens",
    shop:"Devi",
    customer: "Rachel Leonard",
    purchased: "10",
    Paid: "cancelled",
    total: "148.75",
    check: false,
  },
  {
    id: 14,
    orderId: "95850",
    date: "May 29, 2020",
    status: "Delivered",
    items:"Apple",
    shop:"Devi",
    customer: "Ada Laine",
    purchased: "12",
    Paid: "paid",
    total: "199.75",
    check: false,
  },
  {
    id: 15,
    orderId: "95860",
    date: "May 28, 2020",
    items:"Honey",
    status: "On Hold",
    shop:"Sam Shop",
    customer: "Arden Dean",
    purchased: "12",
    Paid: "cancelled",
    total: "496.75",
    check: false,
  },
  {
    id: 16,
    orderId: "95870",
    date: "May 26, 2020",
    items:"Adaptors",
    status: "Delivered",
    shop:"Deepa Sales",
    customer: "Hector Lovett",
    purchased: "15",
    Paid: "Due",
    total: "89.99",
    check: false,
  },
  {
    id: 17,
    orderId: "95880",
    date: "May 25, 2020",
    status: "On Hold",
    items:"Silks",
    shop:"Design Sales",
    customer: "Tianna Fuller",
    purchased: "10",
    Paid: "Due",
    total: "349.75",
    check: false,
  },
  {
    id: 18,
    orderId: "95880",
    date: "May 24, 2020",
    status: "On Hold",
    items:"Honey",
    shop:"Devi Sales",
    customer: "Aliah Pulis",
    purchased: "10",
    Paid: "paid",
    total: "129.99",
    check: false,
  },
  {
    id: 19,
    orderId: "95890",
    date: "May 23, 2020",
    status: "Delivered",
    items:"Things",
    shop:"Devi",
    customer: "Kendal Gray",
    purchased: "12",
    Paid: "paid",
    total: "24.75",
    check: false,
  },
  {
    id: 20,
    orderId: "95899",
    date: "May 23, 2020",
    items:"Honey",
    status: "Delivered",
    shop:"Devi",
    customer: "Kelis Ford",
    purchased: "10",
    Paid: "Due",
    total: "19.99",
    check: false,
  },
];




//Page Details Data
export const pagesData = [
  {
    id: 1,
    orderId: "95954",
    date: "Jun 4, 2020",
    status: "On Hold",
    customer: "Arnold Armstrong",
    purchased: "HR",
    Paid: "paid",
    total: "249.75",
    check: false,
  },
  {
    id: 2,
    orderId: "95961",
    date: "Jun 3, 2020",
    status: "Delivered",
    customer: "Jean Douglas",
    purchased: "Fitness Tracker",
    Paid: "paid",
    total: "99.49",
    check: false,
  },
  {
    id: 3,
    orderId: "95963",
    date: "May 29, 2020",
    status: "Delivered",
    customer: "Ashley Lawson",
    purchased: "Pink Fitness Tracker",
    Paid: "paid",
    total: "148.75",
    check: false,
  },
  {
    id: 4,
    orderId: "95933",
    date: "May 29, 2020",
    status: "Delivered",
    customer: "Joe Larson",
    purchased: "Sales",
    Paid: "due",
    total: "199.75",
    check: false,
  },
  {
    id: 5,
    orderId: "95947",
    date: "May 28, 2020",
    status: "On Hold",
    customer: "Frances Burns",
    purchased: "Admin",
    Paid: "paid",
    total: "496.75",
    check: false,
  },
  {
    id: 6,
    orderId: "95909",
    date: "May 26, 2020",
    status: "Delivered",
    customer: "Victoria Lynch",
    purchased: "Manager",
    Paid: "paid",
    total: "89.99",
    check: false,
  },
  {
    id: 7,
    orderId: "95902",
    date: "May 25, 2020",
    status: "On Hold",
    customer: "Patrick Newman",
    purchased: "Designer",
    Paid: "cancelled",
    total: "349.75",
    check: false,
  },
  {
    id: 8,
    orderId: "95996",
    date: "May 24, 2020",
    status: "On Hold",
    customer: "Emma Walker",
    purchased: "PM",
    Paid: "paid",
    total: "129.99",
    check: false,
  },
  {
    id: 9,
    orderId: "95982",
    date: "May 23, 2020",
    status: "Delivered",
    customer: "Jane Montegomery",
    purchased: " HOD ",
    Paid: "due",
    total: "249.75",
    check: false,
  },
  {
    id: 10,
    orderId: "95959",
    date: "May 23, 2020",
    status: "Delivered",
    customer: "Jane Harris",
    purchased: "PM",
    Paid: "due",
    total: "19.99",
    check: false,
  },
  {
    id: 11,
    orderId: "95810",
    date: "Jul 4, 2020",
    status: "On Hold",
    customer: "Jakoby Roman",
    purchased: "Sales",
    Paid: "paid",
    total: "249.75",
    check: false,
  },
  {
    id: 12,
    orderId: "95820",
    date: "Jul 3, 2020",
    status: "Delivered",
    customer: "Katherine Moss",
    purchased: "Manager",
    Paid: "paid",
    total: "99.49",
    check: false,
  },
  {
    id: 13,
    orderId: "95830",
    date: "May 29, 2020",
    status: "Delivered",
    customer: "Rachel Leonard",
    purchased: "PM",
    Paid: "cancelled",
    total: "148.75",
    check: false,
  },
  {
    id: 14,
    orderId: "95850",
    date: "May 29, 2020",
    status: "Delivered",
    customer: "Ada Laine",
    purchased: "Marketing",
    Paid: "paid",
    total: "199.75",
    check: false,
  },
  {
    id: 15,
    orderId: "95860",
    date: "May 28, 2020",
    status: "On Hold",
    customer: "Arden Dean",
    purchased: "HR",
    Paid: "cancelled",
    total: "496.75",
    check: false,
  },
  {
    id: 16,
    orderId: "95870",
    date: "May 26, 2020",
    status: "Delivered",
    customer: "Hector Lovett",
    purchased: "HOD",
    Paid: "Due",
    total: "89.99",
    check: false,
  },
  {
    id: 17,
    orderId: "95880",
    date: "May 25, 2020",
    status: "On Hold",
    customer: "Tianna Fuller",
    purchased: "Sales Head",
    Paid: "Due",
    total: "349.75",
    check: false,
  },
  {
    id: 18,
    orderId: "95880",
    date: "May 24, 2020",
    status: "On Hold",
    customer: "Aliah Pulis",
    purchased: "HR",
    Paid: "paid",
    total: "129.99",
    check: false,
  },
  {
    id: 19,
    orderId: "95890",
    date: "May 23, 2020",
    status: "Delivered",
    customer: "Kendal Gray",
    purchased: "Manager",
    Paid: "paid",
    total: "249.75",
    check: false,
  },
  {
    id: 20,
    orderId: "95899",
    date: "May 23, 2020",
    status: "Delivered",
    customer: "Kelis Ford",
    purchased: "Admin",
    Paid: "Due",
    total: "19.99",
    check: false,
  },
];


//Currency data
export const currencyData = [
  {
    id: 1,
    orderId: "95954",
    date: "Jun 4, 2020",
    status: "On Hold",
    customer: "Afghanistan Afghan",
    purchased: ",",
    Paid: "paid",
    symbol:"$",
    subunits:"Cents",
    total: ".",
    check: false,
  },
  {
    id: 2,
    orderId: "95961",
    date: "Jun 3, 2020",
    status: "Delivered",
    customer: "Argentina Peso",
    purchased: ",",
    Paid: "paid",
    subunits:"cents",
    symbol:"ƒ",
    total: ".",
    check: false,
  },
  {
    id: 3,
    orderId: "95963",
    date: "May 29, 2020",
    status: "Delivered",
    customer: "Aruba Guilder",
    symbol:"$",
    purchased: ",",
    subunits:"cents",
    Paid: "paid",
    total: ".",
    check: false,
  },
  {
    id: 4,
    orderId: "95963",
    date: "May 29, 2020",
    status: "Delivered",
    customer: "Aruba Guilder",
    symbol:"$",
    purchased: ",",
    subunits:"cents",
    Paid: "paid",
    total: ".",
    check: false,
  },
  {
    id: 5,
    orderId: "95961",
    date: "Jun 3, 2020",
    status: "Delivered",
    customer: "Argentina Peso",
    purchased: ",",
    Paid: "paid",
    subunits:"cents",
    symbol:"ƒ",
    total: ".",
    check: false,
  },
  {
    id: 6,
    orderId: "95963",
    date: "May 29, 2020",
    status: "Delivered",
    customer: "Aruba Guilder",
    symbol:"$",
    purchased: ",",
    subunits:"cents",
    Paid: "paid",
    total: ".",
    check: false,
  },{
    id: 7,
    orderId: "95963",
    date: "May 29, 2020",
    status: "Delivered",
    customer: "Aruba Guilder",
    symbol:"$",
    purchased: ",",
    subunits:"cents",
    Paid: "paid",
    total: ".",
    check: false,
  },
  {
    id: 8,
    orderId: "95963",
    date: "May 29, 2020",
    status: "Delivered",
    customer: "Aruba Guilder",
    symbol:"$",
    purchased: ",",
    subunits:"cents",
    Paid: "paid",
    total: ".",
    check: false,
  },
  {
    id: 9,
    orderId: "95961",
    date: "Jun 3, 2020",
    status: "Delivered",
    customer: "Argentina Peso",
    purchased: ",",
    Paid: "paid",
    subunits:"cents",
    symbol:"ƒ",
    total: ".",
    check: false,
  },
  {
    id: 10,
    orderId: "95963",
    date: "May 29, 2020",
    status: "Delivered",
    customer: "Aruba Guilder",
    symbol:"$",
    purchased: ",",
    subunits:"cents",
    Paid: "paid",
    total: ". ",
    check: false,
  },
  
];


export const saleOrders = [
  {
    id: 1,
    ref: 4947,
    bill: "Enterprize Year Subscription",
    issue: "Phones",
    due: "10-13-2019",
    total: "599.00",
    status: "Due",
  },
  {
    id: 2,
    ref: 4904,
    bill: "Maintenance Year Subscription",
    issue: "Memory Card",
    due: "06-26-2019",
    total: "99.00",
    status: "Paid",
  },
  {
    id: 3,
    ref: 4829,
    bill: "Enterprize Year Subscription",
    issue: "Mouse",
    due: "10-12-2018",
    total: "599.00",
    status: "Paid",
  },
  {
    id: 4,
    ref: 4830,
    bill: "Enterprize Anniversary Subscription",
    issue: "Pants",
    due: "14-12-2018",
    total: "399.00",
    status: "Paid",
  },
  {
    id: 5,
    ref: 4840,
    bill: "Enterprize Coverage Subscription",
    issue: "Mango",
    due: "13-22-2018",
    total: "99.00",
    status: "Cancelled",
  },
  {
    id: 6,
    ref: 4844,
    bill: "	Manual Subscription Adjustments",
    issue: "Magnets",
    due: "13-22-2018",
    total: "99.00",
    status: "Paid",
  },
  {
    id: 7,
    ref: 4847,
    bill: "Automatic Subscription Adjustments",
    issue: "Stamps",
    due: "13-22-2018",
    total: "99.00",
    status: "Paid",
  },
  {
    id: 8,
    ref: 4848,
    bill: "Tiered Subscription",
    issue: " Screens ",
    due: "13-22-2018",
    total: "99.00",
    status: "Paid",
  },
  {
    id: 9,
    ref: 4849,
    bill: "Tiered Subscription",
    issue: "Cups",
    due: "13-22-2018",
    total: "99.00",
    status: "Paid",
  },
  {
    id: 10,
    ref: 4750,
    bill: "Flexible Subscription Plan",
    issue: "Orange",
    due: "13-22-2018",
    total: "99.00",
    status: "Paid",
  },
  {
    id: 11,
    ref: 4510,
    bill: "Enterprize Year Subscription",
    issue: " Slots ",
    due: "10-13-2019",
    total: "599.00",
    status: "Due",
  },
  {
    id: 12,
    ref: 4520,
    bill: "Maintenance Year Subscription",
    issue: "Fans",
    due: "06-26-2019",
    total: "99.00",
    status: "Paid",
  },
  {
    id: 13,
    ref: 4530,
    bill: "Enterprize Year Subscription",
    issue: "Laptops",
    due: "10-12-2018",
    total: "599.00",
    status: "Paid",
  },
  {
    id: 14,
    ref: 4560,
    bill: "Enterprize Anniversary Subscription",
    issue: "Apple",
    due: "14-12-2018",
    total: "399.00",
    status: "Paid",
  },
  {
    id: 15,
    ref: 4570,
    bill: "Enterprize Coverage Subscription",
    issue: "Sims Cards ",
    due: "13-22-2018",
    total: "99.00",
    status: "Cancelled",
  },
  {
    id: 16,
    ref: 4580,
    bill: "	Manual Subscription Adjustments",
    issue: "Keyboards",
    due: "13-22-2018",
    total: "99.00",
    status: "Paid",
  },
  {
    id: 17,
    ref: 4590,
    bill: "Automatic Subscription Adjustments",
    issue: "Adaptor",
    due: "13-22-2018",
    total: "99.00",
    status: "Paid",
  },
  {
    id: 18,
    ref: 4600,
    bill: "Tiered Subscription",
    issue: "Sim Card Slots",
    due: "13-22-2018",
    total: "99.00",
    status: "Paid",
  },
  {
    id: 19,
    ref: 4425,
    bill: "Tiered Subscription",
    issue: "Apple",
    due: "13-22-2018",
    total: "99.00",
    status: "Paid",
  },
  {
    id: 20,
    ref: 4490,
    bill: "Flexible Subscription Plan",
    issue: "Honey",
    due: "13-22-2018",
    total: "99.00",
    status: "Paid",
  },
];


export const paidOptions = [
  { value: "Paid", label: "Paid" },
  { value: "Due", label: "Due" },
  { value: "Canceled", label: "Cancelled" },
];



export const statusOptions = [
  { value: "Paid", label: "Paid" },
  { value: "Due", label: "Due" },
  { value: "Canceled", label: "Cancelled" },
];

export const filterStatus = [
  { value: "approved", label: "Approved" },
  { value: "pending", label: "Pending" },
  { value: "deleted", label: "Deleted" },
  { value: "rejected", label: "Rejected" },
];

export const filterType = [
  { value: "Deposit", label: "Deposit" },
  { value: "Buy", label: "Buy Coin" },
  { value: "Sell", label: "Sell Coin" },
  { value: "Transfer", label: "Transfer" },
  { value: "Withdraw", label: "Withdraw" },
];

export const filterCoin = [
  { value: "Bitcoin", label: "Bitcoin" },
  { value: "Etherium", label: "Etherium" },
  { value: "Litecoin", label: "Litecoin" },
];

export const filterPaymentmethod = [
  { value: "Paypal", label: "Paypal" },
  { value: "Bank", label: "Bank" },
];
