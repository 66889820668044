import User from "../../../images/avatar/b-sm.jpg";
import User2 from "../../../images/avatar/c-sm.jpg";
import User3 from "../../../images/avatar/a-sm.jpg";
import User4 from "../../../images/avatar/d-sm.jpg";
import User9 from "../../../images/avatar/1qs.png";
import User10 from "../../../images/avatar/cas.png"

export const userData = [
  {
    id: 1,
    avatarBg: "purple",
    name: "Karthik",
    displayName: "Ishtiak",
    dob: "10 Aug, 1980",
    role: "Wisozk",
    checked: false,
    email: "Franz Hyatt III",
    balance: "Mobile Featured",
    phone: "Admin ",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "Admin",
    status: "Active",
    address: "2337 Kildeer Drive",
    state: "Kentucky",
    country: "admin@demo.com",
    designation: "",
    odd:"100",
    projects: "213",
    list:"9",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 2,
    avatarBg: "purple",
    image: User3,
    name: "Aravind",
    dob: "10 Sept, 1990",
    role: "Stamm",
    email: "Greyson Johns",
    balance: "Laptop ",
    checked: false,
    phone: "Sales",
    emailStatus: "success",
    kycStatus: "pending",
    lastLogin: "Sales",
    status: "Pending",
    country: "sales@demo.com",
    list:"11",
    designation: "Merchant",
    projects: "213",
    odd:"100",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 3,
    avatarBg: "info",
    name: "John",
    dob: "19 Jan, 1985",
    role: "Osinski",
    email: "Prof. Kayli Goodwin III",
    balance: "Laptop ",
    checked: false,
    list:"12",
    phone: "HR",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "HR",
    status: "Active",
    odd:"100",
    country: "admin@demo.com",
    designation: "Modaretor",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 4,
    avatarBg: "danger",
    name: "James",
    dob: "24 April, 1985",
    role: "Gulgowski",
    email: "Schuyler Hammes",
    balance: "Tablet",
    odd:"100",
    checked: false,
    list:"10",
    phone: "	Sales",
    emailStatus: "alert",
    kycStatus: "alert",
    lastLogin: "Sales ",
    odd:"100",
    status: "Suspend",
    country: "admin@demo.com",
    designation: "Order Handler",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 5,
    avatarBg: "purple",
    name: "Frances Burns",
    dob: "30 May, 2000",
    role: "Feest",
    image: User,
    list:"10",
    email: "Jamal Runolfsdottir",
    balance: "Home Theater System",
    checked: false,
    phone: "Admin",
    emailStatus: "pending",
    odd:"100",
    kycStatus: "error",
    lastLogin: "Admin",
    status: "Active",
    country: "admin@demo.com",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  // {
  //   id: 6,
  //   avatarBg: "primary",
  //   name: "Alan Butler",
  //   dob: "10 Feb, 1997",
  //   role: "Investor",
  //   image: User2,
  //   email: "butler@example.com",
  //   balance: "440.34",
  //   checked: false,
  //   phone: "9633091706",
  //   emailStatus: "pending",
  //   kycStatus: "warning",
  //   lastLogin: "Admin",
  //   status: "Inactive",
  //   country: "India",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 7,
  //   avatarBg: "warning",
  //   name: "Victoria Lynch",
  //   dob: "02 May, 1993",
  //   role: "Investor",
  //   email: "victoria@example.com",
  //   balance: "59,400.68",
  //   checked: false,
  //   phone: "8119854846",
  //   emailStatus: "success",
  //   kycStatus: "success",
  //   lastLogin: "Super Admin",
  //   status: "Active",
  //   country: "China",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 8,
  //   avatarBg: "success",
  //   name: "Patrick Newman",
  //   dob: "15 Feb, 1997",
  //   role: "Customer",
  //   email: "patrick@example.com",
  //   balance: "30.00",
  //   checked: false,
  //   phone: "9422384474",
  //   emailStatus: "success",
  //   kycStatus: "pending",
  //   lastLogin: "User",
  //   status: "Active",
  //   country: "India",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 9,
  //   avatarBg: "purple",
  //   name: "Jane Harris",
  //   dob: "28 Feb, 1985",
  //   role: "Customer",
  //   image: User4,
  //   email: "harris@example.com",
  //   balance: "5,530.23",
  //   checked: false,
  //   phone: "1234472384",
  //   emailStatus: "pending",
  //   kycStatus: "pending",
  //   lastLogin: "Admin",
  //   status: "Pending",
  //   country: "Vietnam",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 10,
  //   avatarBg: "purple",
  //   name: "Emma Walker",
  //   dob: "30 Dec, 1998",
  //   role: "Investor",
  //   email: "walker@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "success",
  //   lastLogin: "User ",
  //   status: "Active",
  //   country: "United States",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 11,
  //   avatarBg: "pink",
  //   name: "Lilja Peltola",
  //   dob: "30 Dec, 1998",
  //   role: "Investor",
  //   email: "lilja@example.com",
  //   balance: "105.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "pending",
  //   lastLogin: "Admin",
  //   status: "Active",
  //   country: "Canada",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 12,
  //   avatarBg: "secondary",
  //   name: "Annette Hunter",
  //   dob: "30 Dec, 1998",
  //   role: "Investor",
  //   email: "hunter@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "success",
  //   lastLogin: "HR",
  //   status: "Pending",
  //   country: "United States",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 13,
  //   avatarBg: "pink",
  //   name: "Sara Koivisto",
  //   dob: "30 Dec, 1998",
  //   role: "Customer",
  //   email: "sara@example.com",
  //   balance: "165.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "rejected",
  //   kycStatus: "pending",
  //   lastLogin: "Admin",
  //   status: "Active",
  //   country: "Russia",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 14,
  //   avatarBg: "blue",
  //   name: "Kianna Pham",
  //   dob: "30 Dec, 1998",
  //   role: "Admin",
  //   email: "kiana@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "rejected",
  //   lastLogin: "User",
  //   status: "Suspend",
  //   country: "South Korea",
  //   designation: "Accountant",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 15,
  //   avatarBg: "pink",
  //   name: "Raymond Atkins",
  //   dob: "30 Dec, 1998",
  //   role: "Customer",
  //   image: User4,
  //   email: "sara@example.com",
  //   balance: "165.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "rejected",
  //   kycStatus: "pending",
  //   lastLogin: "HR ",
  //   status: "Active",
  //   country: "Russia",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 16,
  //   avatarBg: "blue",
  //   name: "Amira Talley",
  //   dob: "30 Dec, 1998",
  //   role: "Admin",
  //   email: "amira@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "pending",
  //   kycStatus: "rejected",
  //   lastLogin: " HR",
  //   status: "Active",
  //   country: "Saudi Arabia",
  //   designation: "Lecturer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 17,
  //   avatarBg: "secondary",
  //   name: "Lana Steiner",
  //   dob: "30 Dec, 1998",
  //   role: "Admin",
  //   email: "steinar@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "pending",
  //   kycStatus: "rejected",
  //   lastLogin: "Admin",
  //   status: "Pending",
  //   country: "Latvia",
  //   designation: "Accountant",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 18,
  //   avatarBg: "warning",
  //   name: "Joshua Mcnair",
  //   dob: "30 Dec, 1998",
  //   image: User4,
  //   role: "Admin",
  //   email: "joshua@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "pending",
  //   kycStatus: "rejected",
  //   lastLogin: "Sales",
  //   status: "Suspend",
  //   country: "Ireland",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 19,
  //   avatarBg: "secondary",
  //   name: "Asiya Wolff",
  //   dob: "30 Dec, 1998",
  //   role: "Customer",
  //   email: "asia@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "success",
  //   lastLogin: "HR",
  //   status: "Active",
  //   country: "Latvia",
  //   designation: "Accountant",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 20,
  //   avatarBg: "warning",
  //   name: "Fox Mccloud",
  //   dob: "30 Dec, 1998",
  //   role: "Admin",
  //   email: "fox@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "pending",
  //   kycStatus: "rejected",
  //   lastLogin: "HR",
  //   status: "Suspend",
  //   country: "Ireland",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
];


export const merchData = [
  {
    id: 1,
    avatarBg: "purple",
    name: "Karthik",
    displayName: "Ishtiak",
    dob: "10 Aug, 1980",
    role: "Wisozk",
    checked: false,
    email: "Franz Hyatt III",
    balance: "Mobile Featured",
    phone: "Admin ",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "Admin",
    status: "Active",
    address: "2337 Kildeer Drive",
    state: "Kentucky",
    country: "Big Basket",
    designation: "",
    odd:"100",
    projects: "213",
    list:"9",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 2,
    avatarBg: "purple",
    image: User3,
    name: "Aravind",
    dob: "10 Sept, 1990",
    role: "Stamm",
    email: "Greyson Johns",
    balance: "Laptop ",
    checked: false,
    phone: "Sales",
    emailStatus: "success",
    kycStatus: "pending",
    lastLogin: "Sales",
    status: "Pending",
    country: "Top Shop",
    list:"11",
    designation: "Merchant",
    projects: "213",
    odd:"100",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 3,
    avatarBg: "info",
    name: "John",
    dob: "19 Jan, 1985",
    role: "Osinski",
    email: "Prof. Kayli Goodwin III",
    balance: "Laptop ",
    checked: false,
    list:"12",
    phone: "HR",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "HR",
    status: "Active",
    odd:"100",
    country: "All Shop",
    designation: "Modaretor",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 4,
    avatarBg: "danger",
    name: "James",
    dob: "24 April, 1985",
    role: "Gulgowski",
    email: "Schuyler Hammes",
    balance: "Tablet",
    odd:"100",
    checked: false,
    list:"10",
    phone: "	Sales",
    emailStatus: "alert",
    kycStatus: "alert",
    lastLogin: "Sales ",
    odd:"100",
    status: "Suspend",
    country: "Street Shop",
    designation: "Order Handler",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 5,
    avatarBg: "purple",
    name: "Frances Burns",
    dob: "30 May, 2000",
    role: "Feest",
    image: User,
    list:"10",
    email: "Jamal Runolfsdottir",
    balance: "Home Theater System",
    checked: false,
    phone: "Admin",
    emailStatus: "pending",
    odd:"100",
    kycStatus: "error",
    lastLogin: "Admin",
    status: "Active",
    country: "Value Shop",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  // {
  //   id: 6,
  //   avatarBg: "primary",
  //   name: "Alan Butler",
  //   dob: "10 Feb, 1997",
  //   role: "Investor",
  //   image: User2,
  //   email: "butler@example.com",
  //   balance: "440.34",
  //   checked: false,
  //   phone: "9633091706",
  //   emailStatus: "pending",
  //   kycStatus: "warning",
  //   lastLogin: "Admin",
  //   status: "Inactive",
  //   country: "India",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 7,
  //   avatarBg: "warning",
  //   name: "Victoria Lynch",
  //   dob: "02 May, 1993",
  //   role: "Investor",
  //   email: "victoria@example.com",
  //   balance: "59,400.68",
  //   checked: false,
  //   phone: "8119854846",
  //   emailStatus: "success",
  //   kycStatus: "success",
  //   lastLogin: "Super Admin",
  //   status: "Active",
  //   country: "China",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 8,
  //   avatarBg: "success",
  //   name: "Patrick Newman",
  //   dob: "15 Feb, 1997",
  //   role: "Customer",
  //   email: "patrick@example.com",
  //   balance: "30.00",
  //   checked: false,
  //   phone: "9422384474",
  //   emailStatus: "success",
  //   kycStatus: "pending",
  //   lastLogin: "User",
  //   status: "Active",
  //   country: "India",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 9,
  //   avatarBg: "purple",
  //   name: "Jane Harris",
  //   dob: "28 Feb, 1985",
  //   role: "Customer",
  //   image: User4,
  //   email: "harris@example.com",
  //   balance: "5,530.23",
  //   checked: false,
  //   phone: "1234472384",
  //   emailStatus: "pending",
  //   kycStatus: "pending",
  //   lastLogin: "Admin",
  //   status: "Pending",
  //   country: "Vietnam",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 10,
  //   avatarBg: "purple",
  //   name: "Emma Walker",
  //   dob: "30 Dec, 1998",
  //   role: "Investor",
  //   email: "walker@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "success",
  //   lastLogin: "User ",
  //   status: "Active",
  //   country: "United States",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 11,
  //   avatarBg: "pink",
  //   name: "Lilja Peltola",
  //   dob: "30 Dec, 1998",
  //   role: "Investor",
  //   email: "lilja@example.com",
  //   balance: "105.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "pending",
  //   lastLogin: "Admin",
  //   status: "Active",
  //   country: "Canada",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 12,
  //   avatarBg: "secondary",
  //   name: "Annette Hunter",
  //   dob: "30 Dec, 1998",
  //   role: "Investor",
  //   email: "hunter@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "success",
  //   lastLogin: "HR",
  //   status: "Pending",
  //   country: "United States",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 13,
  //   avatarBg: "pink",
  //   name: "Sara Koivisto",
  //   dob: "30 Dec, 1998",
  //   role: "Customer",
  //   email: "sara@example.com",
  //   balance: "165.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "rejected",
  //   kycStatus: "pending",
  //   lastLogin: "Admin",
  //   status: "Active",
  //   country: "Russia",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 14,
  //   avatarBg: "blue",
  //   name: "Kianna Pham",
  //   dob: "30 Dec, 1998",
  //   role: "Admin",
  //   email: "kiana@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "rejected",
  //   lastLogin: "User",
  //   status: "Suspend",
  //   country: "South Korea",
  //   designation: "Accountant",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 15,
  //   avatarBg: "pink",
  //   name: "Raymond Atkins",
  //   dob: "30 Dec, 1998",
  //   role: "Customer",
  //   image: User4,
  //   email: "sara@example.com",
  //   balance: "165.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "rejected",
  //   kycStatus: "pending",
  //   lastLogin: "HR ",
  //   status: "Active",
  //   country: "Russia",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 16,
  //   avatarBg: "blue",
  //   name: "Amira Talley",
  //   dob: "30 Dec, 1998",
  //   role: "Admin",
  //   email: "amira@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "pending",
  //   kycStatus: "rejected",
  //   lastLogin: " HR",
  //   status: "Active",
  //   country: "Saudi Arabia",
  //   designation: "Lecturer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 17,
  //   avatarBg: "secondary",
  //   name: "Lana Steiner",
  //   dob: "30 Dec, 1998",
  //   role: "Admin",
  //   email: "steinar@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "pending",
  //   kycStatus: "rejected",
  //   lastLogin: "Admin",
  //   status: "Pending",
  //   country: "Latvia",
  //   designation: "Accountant",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 18,
  //   avatarBg: "warning",
  //   name: "Joshua Mcnair",
  //   dob: "30 Dec, 1998",
  //   image: User4,
  //   role: "Admin",
  //   email: "joshua@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "pending",
  //   kycStatus: "rejected",
  //   lastLogin: "Sales",
  //   status: "Suspend",
  //   country: "Ireland",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 19,
  //   avatarBg: "secondary",
  //   name: "Asiya Wolff",
  //   dob: "30 Dec, 1998",
  //   role: "Customer",
  //   email: "asia@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "success",
  //   lastLogin: "HR",
  //   status: "Active",
  //   country: "Latvia",
  //   designation: "Accountant",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 20,
  //   avatarBg: "warning",
  //   name: "Fox Mccloud",
  //   dob: "30 Dec, 1998",
  //   role: "Admin",
  //   email: "fox@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "pending",
  //   kycStatus: "rejected",
  //   lastLogin: "HR",
  //   status: "Suspend",
  //   country: "Ireland",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
];

export const catData = [
  {
    id: 1,
    avatarBg: "purple",
    name: "",
    image: User9,
    displayName: "Ishtiak",
    dob: "10 Aug, 1980",
    role: "Wisozk",
    checked: false,
    email: "",
    balance: "Motherhood",
    phone: "Allter Bamboo Baby Wipes – Pack of 1",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "8",
    status: "Active",
    address: "2337 Kildeer Drive",
    state: "Kentucky",
    country: "0",
    designation: "Admin",
    odd:"100",
    projects: "213",
    list:"9",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 2,
    avatarBg: "purple",
    image: User10,
    name: "",
    dob: "10 Sept, 1990",
    role: "Stamm",
    email: "",
    balance: "Gears & Toys ",
    checked: false,
    phone: "Shop Baby Lotion & Baby Body Milk Online",
    emailStatus: "success",
    kycStatus: "pending",
    lastLogin: "9",
    status: "Pending",
    country: "0",
    list:"11",
    designation: "Merchant",
    projects: "213",
    odd:"100",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 3,
    avatarBg: "info",
    image: User9,
    name: "",
    dob: "19 Jan, 1985",
    role: "Osinski",
    email: "",
    balance: " Motherhood ",
    checked: false,
    list:"12",
    phone: "Allter Bamboo Baby Wipes – Pack of 1",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "12",
    status: "Active",
    odd:"100",
    country: "0",
    designation: "Modaretor",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 4,
    avatarBg: "danger",
    name: "",
    image: User10,
    dob: "24 April, 1985",
    role: "Gulgowski",
    email: "",
    balance: "Gears & Toys",
    odd:"100",
    checked: false,
    list:"10",
    phone: "	Shop Baby Lotion & Baby Body Milk Online",
    emailStatus: "alert",
    kycStatus: "alert",
    lastLogin: "2",
    odd:"100",
    status: "Suspend",
    country: "0",
    designation: "Order Handler",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 5,
    avatarBg: "purple",
    name: "",
    image: User9,
    dob: "30 May, 2000",
    role: "Feest",
    
    list:"10",
    email: "",
    balance: "Motherhood",
    checked: false,
    phone: "Shop Baby Lotion & Baby Body Milk Online",
    emailStatus: "pending",
    odd:"100",
    kycStatus: "error",
    lastLogin: "2",
    status: "Active",
    country: "0",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  // {
  //   id: 6,
  //   avatarBg: "primary",
  //   name: "Alan Butler",
  //   dob: "10 Feb, 1997",
  //   role: "Investor",
  //   image: User2,
  //   email: "butler@example.com",
  //   balance: "440.34",
  //   checked: false,
  //   phone: "9633091706",
  //   emailStatus: "pending",
  //   kycStatus: "warning",
  //   lastLogin: "Admin",
  //   status: "Inactive",
  //   country: "India",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 7,
  //   avatarBg: "warning",
  //   name: "Victoria Lynch",
  //   dob: "02 May, 1993",
  //   role: "Investor",
  //   email: "victoria@example.com",
  //   balance: "59,400.68",
  //   checked: false,
  //   phone: "8119854846",
  //   emailStatus: "success",
  //   kycStatus: "success",
  //   lastLogin: "Super Admin",
  //   status: "Active",
  //   country: "China",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 8,
  //   avatarBg: "success",
  //   name: "Patrick Newman",
  //   dob: "15 Feb, 1997",
  //   role: "Customer",
  //   email: "patrick@example.com",
  //   balance: "30.00",
  //   checked: false,
  //   phone: "9422384474",
  //   emailStatus: "success",
  //   kycStatus: "pending",
  //   lastLogin: "User",
  //   status: "Active",
  //   country: "India",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 9,
  //   avatarBg: "purple",
  //   name: "Jane Harris",
  //   dob: "28 Feb, 1985",
  //   role: "Customer",
  //   image: User4,
  //   email: "harris@example.com",
  //   balance: "5,530.23",
  //   checked: false,
  //   phone: "1234472384",
  //   emailStatus: "pending",
  //   kycStatus: "pending",
  //   lastLogin: "Admin",
  //   status: "Pending",
  //   country: "Vietnam",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 10,
  //   avatarBg: "purple",
  //   name: "Emma Walker",
  //   dob: "30 Dec, 1998",
  //   role: "Investor",
  //   email: "walker@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "success",
  //   lastLogin: "User ",
  //   status: "Active",
  //   country: "United States",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 11,
  //   avatarBg: "pink",
  //   name: "Lilja Peltola",
  //   dob: "30 Dec, 1998",
  //   role: "Investor",
  //   email: "lilja@example.com",
  //   balance: "105.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "pending",
  //   lastLogin: "Admin",
  //   status: "Active",
  //   country: "Canada",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 12,
  //   avatarBg: "secondary",
  //   name: "Annette Hunter",
  //   dob: "30 Dec, 1998",
  //   role: "Investor",
  //   email: "hunter@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "success",
  //   lastLogin: "HR",
  //   status: "Pending",
  //   country: "United States",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 13,
  //   avatarBg: "pink",
  //   name: "Sara Koivisto",
  //   dob: "30 Dec, 1998",
  //   role: "Customer",
  //   email: "sara@example.com",
  //   balance: "165.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "rejected",
  //   kycStatus: "pending",
  //   lastLogin: "Admin",
  //   status: "Active",
  //   country: "Russia",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 14,
  //   avatarBg: "blue",
  //   name: "Kianna Pham",
  //   dob: "30 Dec, 1998",
  //   role: "Admin",
  //   email: "kiana@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "rejected",
  //   lastLogin: "User",
  //   status: "Suspend",
  //   country: "South Korea",
  //   designation: "Accountant",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 15,
  //   avatarBg: "pink",
  //   name: "Raymond Atkins",
  //   dob: "30 Dec, 1998",
  //   role: "Customer",
  //   image: User4,
  //   email: "sara@example.com",
  //   balance: "165.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "rejected",
  //   kycStatus: "pending",
  //   lastLogin: "HR ",
  //   status: "Active",
  //   country: "Russia",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 16,
  //   avatarBg: "blue",
  //   name: "Amira Talley",
  //   dob: "30 Dec, 1998",
  //   role: "Admin",
  //   email: "amira@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "pending",
  //   kycStatus: "rejected",
  //   lastLogin: " HR",
  //   status: "Active",
  //   country: "Saudi Arabia",
  //   designation: "Lecturer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 17,
  //   avatarBg: "secondary",
  //   name: "Lana Steiner",
  //   dob: "30 Dec, 1998",
  //   role: "Admin",
  //   email: "steinar@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "pending",
  //   kycStatus: "rejected",
  //   lastLogin: "Admin",
  //   status: "Pending",
  //   country: "Latvia",
  //   designation: "Accountant",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 18,
  //   avatarBg: "warning",
  //   name: "Joshua Mcnair",
  //   dob: "30 Dec, 1998",
  //   image: User4,
  //   role: "Admin",
  //   email: "joshua@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "pending",
  //   kycStatus: "rejected",
  //   lastLogin: "Sales",
  //   status: "Suspend",
  //   country: "Ireland",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 19,
  //   avatarBg: "secondary",
  //   name: "Asiya Wolff",
  //   dob: "30 Dec, 1998",
  //   role: "Customer",
  //   email: "asia@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "success",
  //   lastLogin: "HR",
  //   status: "Active",
  //   country: "Latvia",
  //   designation: "Accountant",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 20,
  //   avatarBg: "warning",
  //   name: "Fox Mccloud",
  //   dob: "30 Dec, 1998",
  //   role: "Admin",
  //   email: "fox@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "pending",
  //   kycStatus: "rejected",
  //   lastLogin: "HR",
  //   status: "Suspend",
  //   country: "Ireland",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
];
export const listsData = [
  {
    id: 1,
    avatarBg: "purple",
    name: "Karthik",
    displayName: "Ishtiak",
    dob: "10 Aug, 1980",
    role: "Wisozk",
    checked: false,
    email: "Franz Hyatt III",
    balance: " Ms. Era Padberg ",
    phone: " bergstrom.thea@yahoo.com",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "8",
    status: "Active",
    address: "2337 Kildeer Drive",
    state: "Kentucky",
    country: "Big Shop",
    designation: "Admin",
    odd:"100",
    projects: "213",
    list:"9",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 2,
    avatarBg: "purple",
    image: User3,
    name: "Aravind",
    dob: "10 Sept, 1990",
    role: "Stamm",
    email: "Greyson Johns",
    balance: "Timmothy Murphy 	 ",
    checked: false,
    phone: " osinski.athena@koss.com",
    emailStatus: "success",
    kycStatus: "pending",
    lastLogin: "9",
    status: "Pending",
    country: "Mart",
    list:"11",
    designation: "Merchant",
    projects: "213",
    odd:"100",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 3,
    avatarBg: "info",
    name: "John",
    dob: "19 Jan, 1985",
    role: "Osinski",
    email: "Prof. Kayli Goodwin III",
    balance: " Mr. Bret Rippin IV  ",
    checked: false,
    list:"12",
    phone: " bergstrom.thea@yahoo.com",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "12",
    status: "Active",
    odd:"100",
    country: "BAsket",
    designation: "Modaretor",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 4,
    avatarBg: "danger",
    name: "James",
    dob: "24 April, 1985",
    role: "Gulgowski",
    email: "Schuyler Hammes",
    balance: " Alyce Kuhic ",
    odd:"100",
    checked: false,
    list:"10",
    phone: " gust38@zieme.com",
    emailStatus: "alert",
    kycStatus: "alert",
    lastLogin: "2",
    odd:"100",
    status: "Suspend",
    country: "Stores",
    designation: "Order Handler",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 5,
    avatarBg: "purple",
    name: "Frances Burns",
    dob: "30 May, 2000",
    role: "Feest",
    image: User,
    list:"10",
    email: "Jamal Runolfsdottir",
    balance: " Kelvin Bernhard ",
    checked: false,
    phone: " bergstrom.thea@yahoo.com",
    emailStatus: "pending",
    odd:"100",
    kycStatus: "error",
    lastLogin: "2",
    status: "Active",
    country: "Wall Street",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  // {
  //   id: 6,
  //   avatarBg: "primary",
  //   name: "Alan Butler",
  //   dob: "10 Feb, 1997",
  //   role: "Investor",
  //   image: User2,
  //   email: "butler@example.com",
  //   balance: "440.34",
  //   checked: false,
  //   phone: "9633091706",
  //   emailStatus: "pending",
  //   kycStatus: "warning",
  //   lastLogin: "Admin",
  //   status: "Inactive",
  //   country: "India",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 7,
  //   avatarBg: "warning",
  //   name: "Victoria Lynch",
  //   dob: "02 May, 1993",
  //   role: "Investor",
  //   email: "victoria@example.com",
  //   balance: "59,400.68",
  //   checked: false,
  //   phone: "8119854846",
  //   emailStatus: "success",
  //   kycStatus: "success",
  //   lastLogin: "Super Admin",
  //   status: "Active",
  //   country: "China",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 8,
  //   avatarBg: "success",
  //   name: "Patrick Newman",
  //   dob: "15 Feb, 1997",
  //   role: "Customer",
  //   email: "patrick@example.com",
  //   balance: "30.00",
  //   checked: false,
  //   phone: "9422384474",
  //   emailStatus: "success",
  //   kycStatus: "pending",
  //   lastLogin: "User",
  //   status: "Active",
  //   country: "India",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 9,
  //   avatarBg: "purple",
  //   name: "Jane Harris",
  //   dob: "28 Feb, 1985",
  //   role: "Customer",
  //   image: User4,
  //   email: "harris@example.com",
  //   balance: "5,530.23",
  //   checked: false,
  //   phone: "1234472384",
  //   emailStatus: "pending",
  //   kycStatus: "pending",
  //   lastLogin: "Admin",
  //   status: "Pending",
  //   country: "Vietnam",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 10,
  //   avatarBg: "purple",
  //   name: "Emma Walker",
  //   dob: "30 Dec, 1998",
  //   role: "Investor",
  //   email: "walker@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "success",
  //   lastLogin: "User ",
  //   status: "Active",
  //   country: "United States",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 11,
  //   avatarBg: "pink",
  //   name: "Lilja Peltola",
  //   dob: "30 Dec, 1998",
  //   role: "Investor",
  //   email: "lilja@example.com",
  //   balance: "105.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "pending",
  //   lastLogin: "Admin",
  //   status: "Active",
  //   country: "Canada",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 12,
  //   avatarBg: "secondary",
  //   name: "Annette Hunter",
  //   dob: "30 Dec, 1998",
  //   role: "Investor",
  //   email: "hunter@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "success",
  //   lastLogin: "HR",
  //   status: "Pending",
  //   country: "United States",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 13,
  //   avatarBg: "pink",
  //   name: "Sara Koivisto",
  //   dob: "30 Dec, 1998",
  //   role: "Customer",
  //   email: "sara@example.com",
  //   balance: "165.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "rejected",
  //   kycStatus: "pending",
  //   lastLogin: "Admin",
  //   status: "Active",
  //   country: "Russia",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 14,
  //   avatarBg: "blue",
  //   name: "Kianna Pham",
  //   dob: "30 Dec, 1998",
  //   role: "Admin",
  //   email: "kiana@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "rejected",
  //   lastLogin: "User",
  //   status: "Suspend",
  //   country: "South Korea",
  //   designation: "Accountant",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 15,
  //   avatarBg: "pink",
  //   name: "Raymond Atkins",
  //   dob: "30 Dec, 1998",
  //   role: "Customer",
  //   image: User4,
  //   email: "sara@example.com",
  //   balance: "165.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "rejected",
  //   kycStatus: "pending",
  //   lastLogin: "HR ",
  //   status: "Active",
  //   country: "Russia",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 16,
  //   avatarBg: "blue",
  //   name: "Amira Talley",
  //   dob: "30 Dec, 1998",
  //   role: "Admin",
  //   email: "amira@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "pending",
  //   kycStatus: "rejected",
  //   lastLogin: " HR",
  //   status: "Active",
  //   country: "Saudi Arabia",
  //   designation: "Lecturer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 17,
  //   avatarBg: "secondary",
  //   name: "Lana Steiner",
  //   dob: "30 Dec, 1998",
  //   role: "Admin",
  //   email: "steinar@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "pending",
  //   kycStatus: "rejected",
  //   lastLogin: "Admin",
  //   status: "Pending",
  //   country: "Latvia",
  //   designation: "Accountant",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 18,
  //   avatarBg: "warning",
  //   name: "Joshua Mcnair",
  //   dob: "30 Dec, 1998",
  //   image: User4,
  //   role: "Admin",
  //   email: "joshua@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "pending",
  //   kycStatus: "rejected",
  //   lastLogin: "Sales",
  //   status: "Suspend",
  //   country: "Ireland",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 19,
  //   avatarBg: "secondary",
  //   name: "Asiya Wolff",
  //   dob: "30 Dec, 1998",
  //   role: "Customer",
  //   email: "asia@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "success",
  //   lastLogin: "HR",
  //   status: "Active",
  //   country: "Latvia",
  //   designation: "Accountant",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 20,
  //   avatarBg: "warning",
  //   name: "Fox Mccloud",
  //   dob: "30 Dec, 1998",
  //   role: "Admin",
  //   email: "fox@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "pending",
  //   kycStatus: "rejected",
  //   lastLogin: "HR",
  //   status: "Suspend",
  //   country: "Ireland",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
];



export const langData = [
  {
    id: 1,
    avatarBg: "purple",
    name: "Kartooohik",
    displayName: "Ishtiak",
    dob: "10 Aug, 1980",
    role: "Customer",
    checked: false,
    email: "info@babyamore.in",
    balance: " English ",
    phone: " 35,040.34",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "20",
    status: "Active",
    address: "2337 Kildeer Drive",
    state: "Kentucky",
    country: "Canada",
    designation: "Admin",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 2,
    avatarBg: "purple",
    image: User3,
    name: "Aravind",
    dob: "10 Sept, 1990",
    role: "Investor",
    email: "ashley@babyamore.in",
    balance: " Bangla ",
    checked: false,
    phone: " 580.00",
    emailStatus: "success",
    kycStatus: "pending",
    lastLogin: "020",
    status: "Pending",
    country: "United States",
    designation: "Merchant",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 3,
    avatarBg: "info",
    name: "John",
    dob: "19 Jan, 1985",
    role: "Customer",
    email: "larson@example.com",
    balance: " Ukrainian ",
    checked: false,
    phone: "  32,000.34",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "0020",
    status: "Active",
    country: "England",
    designation: "Modaretor",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 4,
    avatarBg: "danger",
    name: "James",
    dob: "24 April, 1985",
    role: "Subscriber",
    email: "jane84@example.com",
    balance: " Vietnamese",
    checked: false,
    phone: " 0.00",
    emailStatus: "alert",
    kycStatus: "alert",
    lastLogin: "020",
    status: "Suspend",
    country: "United States",
    designation: "Order Handler",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 5,
    avatarBg: "purple",
    name: "Frances Burns",
    dob: "30 May, 2000",
    role: "Manager",
    image: User,
    email: "frances@example.com",
    balance: " Indonesian ",
    checked: false,
    phone: "  42.50",
    emailStatus: "pending",
    kycStatus: "error",
    lastLogin: "310",
    status: "Active",
    country: "Bangladesh",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 6,
    avatarBg: "primary",
    name: "Alan Butler",
    dob: "10 Feb, 1997",
    role: "Investor",
    image: User2,
    email: "butler@example.com",
    balance: " Spanish ",
    checked: false,
    phone: " 440.34",
    emailStatus: "pending",
    kycStatus: "warning",
    lastLogin: "120",
    status: "Inactive",
    country: "India",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 7,
    avatarBg: "warning",
    name: "Victoria Lynch",
    dob: "02 May, 1993",
    role: "Investor",
    email: "victoria@example.com",
    balance: " Vietnamese ",
    checked: false,
    phone: " 59,400.68",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "120",
    status: "Active",
    country: "China",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 8,
    avatarBg: "success",
    name: "Patrick Newman",
    dob: "15 Feb, 1997",
    role: "Customer",
    email: "patrick@example.com",
    balance: " Turkish ",
    checked: false,
    phone: "30.00 ",
    emailStatus: "success",
    kycStatus: "pending",
    lastLogin: "020",
    status: "Active",
    country: "India",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 9,
    avatarBg: "purple",
    name: "Jane Harris",
    dob: "28 Feb, 1985",
    role: "Customer",
    image: User4,
    email: "harris@example.com",
    balance: " Thai ",
    checked: false,
    phone: " 5,530.23",
    emailStatus: "pending",
    kycStatus: "pending",
    lastLogin: "0020",
    status: "Pending",
    country: "Vietnam",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 10,
    avatarBg: "purple",
    name: "Emma Walker",
    dob: "30 Dec, 1998",
    role: "Investor",
    email: "walker@example.com",
    balance: " Bangla ",
    checked: false,
    phone: " 55.00 ",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "2019",
    status: "Active",
    country: "United States",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 11,
    avatarBg: "pink",
    name: "Lilja Peltola",
    dob: "30 Dec, 1998",
    role: "Investor",
    email: "lilja@example.com",
    balance: " Ukrainan ",
    checked: false,
    phone: " 105.00 ",
    emailStatus: "success",
    kycStatus: "pending",
    lastLogin: "25",
    status: "Active",
    country: "Canada",
    designation: "Web Developer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 12,
    avatarBg: "secondary",
    name: "Annette Hunter",
    dob: "30 Dec, 1998",
    role: "Investor",
    email: "hunter@example.com",
    balance: " Persian",
    checked: false,
    phone: "  55.00 ",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "25019",
    status: "Pending",
    country: "United States",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 13,
    avatarBg: "pink",
    name: "Sara Koivisto",
    dob: "30 Dec, 1998",
    role: "Customer",
    email: "sara@example.com",
    balance: " English ",
    checked: false,
    phone: "  165.00",
    emailStatus: "rejected",
    kycStatus: "pending",
    lastLogin: "2519",
    status: "Active",
    country: "Russia",
    designation: "Web Developer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 14,
    avatarBg: "blue",
    name: "Kianna Pham",
    dob: "30 Dec, 1998",
    role: "Admin",
    email: "kiana@example.com",
    balance: " Spanish ",
    checked: false,
    phone: " 55.00",
    emailStatus: "success",
    kycStatus: "rejected",
    lastLogin: "25019",
    status: "Suspend",
    country: "South Korea",
    designation: "Accountant",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 15,
    avatarBg: "pink",
    name: "Raymond Atkins",
    dob: "30 Dec, 1998",
    role: "Customer",
    image: User4,
    email: "sara@example.com",
    balance: " Turkish",
    checked: false,
    phone:  "   165.00",
    emailStatus: "rejected",
    kycStatus: "pending",
    lastLogin: "2519",
    status: "Active",
    country: "Russia",
    designation: "Web Developer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 16,
    avatarBg: "blue",
    name: "Amira Talley",
    dob: "30 Dec, 1998",
    role: "Admin",
    email: "amira@example.com",
    balance: "  English ",
    checked: false,
    phone: " 55.00 ",
    emailStatus: "pending",
    kycStatus: "rejected",
    lastLogin: "2509",
    status: "Active",
    country: "Saudi Arabia",
    designation: "Lecturer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 17,
    avatarBg: "secondary",
    name: "Lana Steiner",
    dob: "30 Dec, 1998",
    role: "Admin",
    email: "steinar@example.com",
    balance: " Persian  ",
    checked: false,
    phone: " 55.00",
    emailStatus: "pending",
    kycStatus: "rejected",
    lastLogin: "2519",
    status: "Pending",
    country: "Latvia",
    designation: "Accountant",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 18,
    avatarBg: "warning",
    name: "Joshua Mcnair",
    dob: "30 Dec, 1998",
    image: User4,
    role: "Admin",
    email: "joshua@example.com",
    balance: " Spanish ",
    checked: false,
    phone: "  55.00",
    emailStatus: "pending",
    kycStatus: "rejected",
    lastLogin: "259",
    status: "Suspend",
    country: "Ireland",
    designation: "Web Developer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 19,
    avatarBg: "secondary",
    name: "Asiya Wolff",
    dob: "30 Dec, 1998",
    role: "Customer",
    email: "asia@example.com",
    balance: "  Turkish  ",
    checked: false,
    phone: " 55.00",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "2519",
    status: "Active",
    country: "Latvia",
    designation: "Accountant",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 20,
    avatarBg: "warning",
    name: "Fox Mccloud",
    dob: "30 Dec, 1998",
    role: "Admin",
    email: "fox@example.com",
    balance:  " Indonesian ",
    checked: false,
    phone: "55.00",
    emailStatus: "pending",
    kycStatus: "rejected",
    lastLogin: "219",
    status: "Suspend",
    country: "Ireland",
    designation: "Web Developer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
];


export const notes = [
  {
    id: 1,
    text: "Aproin at metus et dolor tincidunt feugiat eu id quam. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean sollicitudin non nunc vel pharetra.",
    date: "November 18, 2019",
    time: "5:34 pm",
    company: "Softnio",
  },
  {
    id: 2,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eleifend libero semper metus aliquam tempus. Sed efficitur elit et ligula lobortis",
    date: "December 27, 2019",
    time: "7:00 pm",
    company: "Softnio",
  },
];

export const filterStatus = [
  { value: "Active", label: "Active" },
  { value: "Pending", label: "Pending" },
  { value: "Suspend", label: "Suspend" },
];

export const filterRole = [
  { value: "investor", label: "Investor" },
  { value: "seller", label: "Seller" },
  { value: "buyer", label: "Buyer" },
];

export const countryOptions = [
  { value: "Canada", label: "Canada" },
  { value: "USA", label: "USA" },
  { value: "India", label: "India" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "France", label: "France" },
  { value: "England", label: "England" },
];
