import React from "react";
import Icon from "../../components/icon/Icon";

const HeaderSearch = () => {
  return (
    <React.Fragment>
      Everyday is a Fashion Show and the World is your Runway...
    </React.Fragment>
  );
};

export default HeaderSearch;
