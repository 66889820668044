export const sampleData = [
    {
      id: 1,
      code: "95954",
      type: "Arnold Armstrong",
      discount: "249.75",
      status: "On Hold",
      sdate: "Jun 4, 2020",
      edate: "Jun 4, 2020",
      
      // purchased: "3 items",
      // Paid: "paid",
      check: false,
    },
    {
      id: 2,
      orderId: "95961",
      type: "Jean Douglas",
      discount: "99.49",
      status: "Delivered",
      sdate: "Jun 3, 2020",
      edate: "Jun 3, 2020",
      // purchased: "Fitness Tracker",
      // Paid: "paid",
      check: false,
    },
    {
      id: 3,
      orderId: "95963",
      type: "Ashley Lawson",
      discount: "148.75",
      status: "Delivered",
      sdate: "May 29, 2020",
      edate: "May 29, 2020",
      // purchased: "Pink Fitness Tracker",
      // Paid: "paid",
      check: false,
    },
    {
      id: 4,
      orderId: "95933",
      type: "Joe Larson",
      discount: "199.75",
      status: "Delivered",
      sdate: "May 29, 2020",
      edate: "May 29, 2020",
      // purchased: "2 items",
      // Paid: "due",
      check: false,
    },
    {
      id: 5,
      orderId: "95947",
      type: "Frances Burns",
      discount: "496.75",
      status: "On Hold",
      sdate: "May 28, 2020",
      edate: "May 28, 2020",
      // purchased: "6 items",
      // Paid: "paid",
      check: false,
    },
    // {
    //   id: 6,
    //   orderId: "95909",
    //   date: "May 26, 2020",
    //   status: "Delivered",
    //   customer: "Victoria Lynch",
    //   purchased: "Waterproof Speakers",
    //   Paid: "paid",
    //   total: "89.99",
    //   check: false,
    // },
    // {
    //   id: 7,
    //   orderId: "95902",
    //   date: "May 25, 2020",
    //   status: "On Hold",
    //   customer: "Patrick Newman",
    //   purchased: "4 items",
    //   Paid: "cancelled",
    //   total: "349.75",
    //   check: false,
    // },
    // {
    //   id: 8,
    //   orderId: "95996",
    //   date: "May 24, 2020",
    //   status: "On Hold",
    //   customer: "Emma Walker",
    //   purchased: "Smart Watch",
    //   Paid: "paid",
    //   total: "129.99",
    //   check: false,
    // },
    // {
    //   id: 9,
    //   orderId: "95982",
    //   date: "May 23, 2020",
    //   status: "Delivered",
    //   customer: "Jane Montegomery",
    //   purchased: "Smart Watch",
    //   Paid: "due",
    //   total: "249.75",
    //   check: false,
    // },
    // {
    //   id: 10,
    //   orderId: "95959",
    //   date: "May 23, 2020",
    //   status: "Delivered",
    //   customer: "Jane Harris",
    //   purchased: "Waterproof Speaker",
    //   Paid: "due",
    //   total: "19.99",
    //   check: false,
    // },
    // {
    //   id: 11,
    //   orderId: "95810",
    //   date: "Jul 4, 2020",
    //   status: "On Hold",
    //   customer: "Jakoby Roman",
    //   purchased: "3 items",
    //   Paid: "paid",
    //   total: "249.75",
    //   check: false,
    // },
    // {
    //   id: 12,
    //   orderId: "95820",
    //   date: "Jul 3, 2020",
    //   status: "Delivered",
    //   customer: "Katherine Moss",
    //   purchased: "Fitness Tracker",
    //   Paid: "paid",
    //   total: "99.49",
    //   check: false,
    // },
    // {
    //   id: 13,
    //   orderId: "95830",
    //   date: "May 29, 2020",
    //   status: "Delivered",
    //   customer: "Rachel Leonard",
    //   purchased: "Pink Fitness Tracker",
    //   Paid: "cancelled",
    //   total: "148.75",
    //   check: false,
    // },
    // {
    //   id: 14,
    //   orderId: "95850",
    //   date: "May 29, 2020",
    //   status: "Delivered",
    //   customer: "Ada Laine",
    //   purchased: "2 items",
    //   Paid: "paid",
    //   total: "199.75",
    //   check: false,
    // },
    // {
    //   id: 15,
    //   orderId: "95860",
    //   date: "May 28, 2020",
    //   status: "On Hold",
    //   customer: "Arden Dean",
    //   purchased: "6 items",
    //   Paid: "cancelled",
    //   total: "496.75",
    //   check: false,
    // },
    // {
    //   id: 16,
    //   orderId: "95870",
    //   date: "May 26, 2020",
    //   status: "Delivered",
    //   customer: "Hector Lovett",
    //   purchased: "Waterproof Speakers",
    //   Paid: "Due",
    //   total: "89.99",
    //   check: false,
    // },
    // {
    //   id: 17,
    //   orderId: "95880",
    //   date: "May 25, 2020",
    //   status: "On Hold",
    //   customer: "Tianna Fuller",
    //   purchased: "4 items",
    //   Paid: "Due",
    //   total: "349.75",
    //   check: false,
    // },
    // {
    //   id: 18,
    //   orderId: "95880",
    //   date: "May 24, 2020",
    //   status: "On Hold",
    //   customer: "Aliah Pulis",
    //   purchased: "Smart Watch",
    //   Paid: "paid",
    //   total: "129.99",
    //   check: false,
    // },
    // {
    //   id: 19,
    //   orderId: "95890",
    //   date: "May 23, 2020",
    //   status: "Delivered",
    //   customer: "Kendal Gray",
    //   purchased: "Smart Watch",
    //   Paid: "paid",
    //   total: "249.75",
    //   check: false,
    // },
    // {
    //   id: 20,
    //   orderId: "95899",
    //   date: "May 23, 2020",
    //   status: "Delivered",
    //   customer: "Kelis Ford",
    //   purchased: "Waterproof Speaker",
    //   Paid: "Due",
    //   total: "19.99",
    //   check: false,
    // },
  ];
  
  export const saleOrders = [
    {
      id: 1,
      ref: 4947,
      bill: "Enterprize Year Subscription",
      issue: "10-05-2019",
      due: "10-13-2019",
      total: "599.00",
      status: "Due",
    },
    {
      id: 2,
      ref: 4904,
      bill: "Maintenance Year Subscription",
      issue: "06-19-2019",
      due: "06-26-2019",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 3,
      ref: 4829,
      bill: "Enterprize Year Subscription",
      issue: "10-04-2018",
      due: "10-12-2018",
      total: "599.00",
      status: "Paid",
    },
    {
      id: 4,
      ref: 4830,
      bill: "Enterprize Anniversary Subscription",
      issue: "12-04-2018",
      due: "14-12-2018",
      total: "399.00",
      status: "Paid",
    },
    {
      id: 5,
      ref: 4840,
      bill: "Enterprize Coverage Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Cancelled",
    },
    {
      id: 6,
      ref: 4844,
      bill: "	Manual Subscription Adjustments",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 7,
      ref: 4847,
      bill: "Automatic Subscription Adjustments",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 8,
      ref: 4848,
      bill: "Tiered Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 9,
      ref: 4849,
      bill: "Tiered Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 10,
      ref: 4750,
      bill: "Flexible Subscription Plan",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 11,
      ref: 4510,
      bill: "Enterprize Year Subscription",
      issue: "10-05-2019",
      due: "10-13-2019",
      total: "599.00",
      status: "Due",
    },
    {
      id: 12,
      ref: 4520,
      bill: "Maintenance Year Subscription",
      issue: "06-19-2019",
      due: "06-26-2019",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 13,
      ref: 4530,
      bill: "Enterprize Year Subscription",
      issue: "10-04-2018",
      due: "10-12-2018",
      total: "599.00",
      status: "Paid",
    },
    {
      id: 14,
      ref: 4560,
      bill: "Enterprize Anniversary Subscription",
      issue: "12-04-2018",
      due: "14-12-2018",
      total: "399.00",
      status: "Paid",
    },
    {
      id: 15,
      ref: 4570,
      bill: "Enterprize Coverage Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Cancelled",
    },
    {
      id: 16,
      ref: 4580,
      bill: "	Manual Subscription Adjustments",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 17,
      ref: 4590,
      bill: "Automatic Subscription Adjustments",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 18,
      ref: 4600,
      bill: "Tiered Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 19,
      ref: 4425,
      bill: "Tiered Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 20,
      ref: 4490,
      bill: "Flexible Subscription Plan",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
  ];
  
  
  export const paidOptions = [
    { value: "Paid", label: "Paid" },
    { value: "Due", label: "Due" },
    { value: "Canceled", label: "Cancelled" },
  ];
  
  
  
  export const statusOptions = [
    { value: "Paid", label: "Paid" },
    { value: "Due", label: "Due" },
    { value: "Canceled", label: "Cancelled" },
  ];
  
  export const filterStatus = [
    { value: "approved", label: "Approved" },
    { value: "pending", label: "Pending" },
    { value: "deleted", label: "Deleted" },
    { value: "rejected", label: "Rejected" },
  ];
  
  export const filterType = [
    { value: "Deposit", label: "Deposit" },
    { value: "Buy", label: "Buy Coin" },
    { value: "Sell", label: "Sell Coin" },
    { value: "Transfer", label: "Transfer" },
    { value: "Withdraw", label: "Withdraw" },
  ];
  
  export const filterCoin = [
    { value: "Bitcoin", label: "Bitcoin" },
    { value: "Etherium", label: "Etherium" },
    { value: "Litecoin", label: "Litecoin" },
  ];
  
  export const filterPaymentmethod = [
    { value: "Paypal", label: "Paypal" },
    { value: "Bank", label: "Bank" },
  ];
  