import User from "../../../images/avatar/b-sm.jpg";
import User2 from "../../../images/avatar/c-sm.jpg";
import User3 from "../../../images/avatar/a-sm.jpg";
import User4 from "../../../images/avatar/d-sm.jpg";
import User7 from "../../../images/avatar/1aq.png"
import User8 from "../../../images/avatar/1212.png";

export const subData = [
  {
    id: 1,
    avatarBg: "purple", 
    image: User7,
    name: "",
    displayName: "Ishtiak",
    dob: "10 Aug, 1980",
    role: "Customer",
    checked: false,
    email: "",
    balance: "Baby Clothes",
    phone: "Allter",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "10 ",
    status: "Active",
    address: "2337 Kildeer Drive",
    state: "Kentucky",
    country: "12",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 2,
    avatarBg: "purple",
    image: User8,
    name: "",
    dob: "10 Sept, 1990",
    role: "Investor",
    email: "",
    balance: "Oral Care",
    checked: false,
    phone: "Baby Works",
    emailStatus: "success",
    kycStatus: "pending",
    lastLogin: "07",
    status: "Pending",
    country: "122",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 3,
    avatarBg: "info",
    image: User7,
    name: "",
    dob: "19 Jan, 1985",
    role: "Customer",
    email: "",
    balance: "Baby Clothes",
    checked: false,
    phone: "Allter",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "04 ",
    status: "Active",
    country: "11",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 4,
    avatarBg: "danger",
    image: User8,
    name: "",
    dob: "24 April, 1985",
    role: "Subscriber",
    email: "",
    balance: "Oral Care",
    checked: false,
    phone: "Baby Works",
    emailStatus: "alert",
    kycStatus: "alert",
    lastLogin: "01 ",
    status: "Suspend",
    country: "14s",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 5,
    avatarBg: "purple",
    name: "",
    image: User7,
    dob: "30 May, 2000",
    role: "Manager",
   
    email: "",
    balance: "Baby Clothes",
    checked: false,
    phone: "Allter",
    emailStatus: "pending",
    kycStatus: "error",
    lastLogin: "31 ",
    status: "Active",
    country: "21",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  // {
  //   id: 6,
  //   avatarBg: "primary",
  //   name: "Alan Butler",
  //   dob: "10 Feb, 1997",
  //   role: "Investor",
  //   image: User2,
  //   email: "butler@example.com",
  //   balance: "440.34",
  //   checked: false,
  //   phone: "9633091706",
  //   emailStatus: "pending",
  //   kycStatus: "warning",
  //   lastLogin: "18 Jan 2020",
  //   status: "Inactive",
  //   country: "India",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 7,
  //   avatarBg: "warning",
  //   name: "Victoria Lynch",
  //   dob: "02 May, 1993",
  //   role: "Investor",
  //   email: "victoria@example.com",
  //   balance: "59,400.68",
  //   checked: false,
  //   phone: "8119854846",
  //   emailStatus: "success",
  //   kycStatus: "success",
  //   lastLogin: "15 Jan 2020",
  //   status: "Active",
  //   country: "China",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 8,
  //   avatarBg: "success",
  //   name: "Patrick Newman",
  //   dob: "15 Feb, 1997",
  //   role: "Customer",
  //   email: "patrick@example.com",
  //   balance: "30.00",
  //   checked: false,
  //   phone: "9422384474",
  //   emailStatus: "success",
  //   kycStatus: "pending",
  //   lastLogin: "08 Jan 2020",
  //   status: "Active",
  //   country: "India",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 9,
  //   avatarBg: "purple",
  //   name: "Jane Harris",
  //   dob: "28 Feb, 1985",
  //   role: "Customer",
  //   image: User4,
  //   email: "harris@example.com",
  //   balance: "5,530.23",
  //   checked: false,
  //   phone: "1234472384",
  //   emailStatus: "pending",
  //   kycStatus: "pending",
  //   lastLogin: "02 Jan 2020",
  //   status: "Pending",
  //   country: "Vietnam",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 10,
  //   avatarBg: "purple",
  //   name: "Emma Walker",
  //   dob: "30 Dec, 1998",
  //   role: "Investor",
  //   email: "walker@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "success",
  //   lastLogin: "25 Dec 2019",
  //   status: "Active",
  //   country: "United States",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 11,
  //   avatarBg: "pink",
  //   name: "Lilja Peltola",
  //   dob: "30 Dec, 1998",
  //   role: "Investor",
  //   email: "lilja@example.com",
  //   balance: "105.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "pending",
  //   lastLogin: "25 Dec 2019",
  //   status: "Active",
  //   country: "Canada",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 12,
  //   avatarBg: "secondary",
  //   name: "Annette Hunter",
  //   dob: "30 Dec, 1998",
  //   role: "Investor",
  //   email: "hunter@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "success",
  //   lastLogin: "25 Dec 2019",
  //   status: "Pending",
  //   country: "United States",
  //   designation: "UI/UX Designer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 13,
  //   avatarBg: "pink",
  //   name: "Sara Koivisto",
  //   dob: "30 Dec, 1998",
  //   role: "Customer",
  //   email: "sara@example.com",
  //   balance: "165.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "rejected",
  //   kycStatus: "pending",
  //   lastLogin: "25 Dec 2019",
  //   status: "Active",
  //   country: "Russia",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 14,
  //   avatarBg: "blue",
  //   name: "Kianna Pham",
  //   dob: "30 Dec, 1998",
  //   role: "Admin",
  //   email: "kiana@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "rejected",
  //   lastLogin: "25 Dec 2019",
  //   status: "Suspend",
  //   country: "South Korea",
  //   designation: "Accountant",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 15,
  //   avatarBg: "pink",
  //   name: "Raymond Atkins",
  //   dob: "30 Dec, 1998",
  //   role: "Customer",
  //   image: User4,
  //   email: "sara@example.com",
  //   balance: "165.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "rejected",
  //   kycStatus: "pending",
  //   lastLogin: "25 Dec 2019",
  //   status: "Active",
  //   country: "Russia",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 16,
  //   avatarBg: "blue",
  //   name: "Amira Talley",
  //   dob: "30 Dec, 1998",
  //   role: "Admin",
  //   email: "amira@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "pending",
  //   kycStatus: "rejected",
  //   lastLogin: "25 Dec 2019",
  //   status: "Active",
  //   country: "Saudi Arabia",
  //   designation: "Lecturer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 17,
  //   avatarBg: "secondary",
  //   name: "Lana Steiner",
  //   dob: "30 Dec, 1998",
  //   role: "Admin",
  //   email: "steinar@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "pending",
  //   kycStatus: "rejected",
  //   lastLogin: "25 Dec 2019",
  //   status: "Pending",
  //   country: "Latvia",
  //   designation: "Accountant",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 18,
  //   avatarBg: "warning",
  //   name: "Joshua Mcnair",
  //   dob: "30 Dec, 1998",
  //   image: User4,
  //   role: "Admin",
  //   email: "joshua@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "pending",
  //   kycStatus: "rejected",
  //   lastLogin: "25 Dec 2019",
  //   status: "Suspend",
  //   country: "Ireland",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 19,
  //   avatarBg: "secondary",
  //   name: "Asiya Wolff",
  //   dob: "30 Dec, 1998",
  //   role: "Customer",
  //   email: "asia@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "success",
  //   kycStatus: "success",
  //   lastLogin: "25 Dec 2019",
  //   status: "Active",
  //   country: "Latvia",
  //   designation: "Accountant",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
  // {
  //   id: 20,
  //   avatarBg: "warning",
  //   name: "Fox Mccloud",
  //   dob: "30 Dec, 1998",
  //   role: "Admin",
  //   email: "fox@example.com",
  //   balance: "55.00",
  //   checked: false,
  //   phone: "4634717173",
  //   emailStatus: "pending",
  //   kycStatus: "rejected",
  //   lastLogin: "25 Dec 2019",
  //   status: "Suspend",
  //   country: "Ireland",
  //   designation: "Web Developer",
  //   projects: "213",
  //   performed: "87.5",
  //   tasks: "587",
  // },
];

export const notes = [
  {
    id: 1,
    text: "Aproin at metus et dolor tincidunt feugiat eu id quam. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean sollicitudin non nunc vel pharetra.",
    date: "November 18, 2019",
    time: "5:34 pm",
    company: "Softnio",
  },
  {
    id: 2,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eleifend libero semper metus aliquam tempus. Sed efficitur elit et ligula lobortis",
    date: "December 27, 2019",
    time: "7:00 pm",
    company: "Softnio",
  },
];

export const filterStatus = [
  { value: "Active", label: "Active" },
  { value: "Pending", label: "Pending" },
  { value: "Suspend", label: "Suspend" },
];

export const filterStatussub = [
  { value: "Beauty and Health", label: "Beauty and Health" },
  { value: "Clothing and Shoes", label: "Clothing and Shoes" },
  { value: "Clothing and Shoes", label: "Clothing and Shoes" },
  { value: "Electronics", label: "Electronics" },
  { value: "Kids & Toy", label: "Kids & Toy" },
  { value: "Pets", label: "Pets" },
  { value: "Sports", label: "Sports" },
];

export const filterStatussub1 = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

export const filterRole = [
  { value: "investor", label: "Investor" },
  { value: "seller", label: "Seller" },
  { value: "buyer", label: "Buyer" },
];

export const countryOptions = [
  { value: "Canada", label: "Canada" },
  { value: "USA", label: "USA" },
  { value: "India", label: "India" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "France", label: "France" },
  { value: "England", label: "England" },
];
